import PropTypes from 'prop-types';
import React from 'react';
import settings from 'settings.js';

const AppButton = ({ store, width = '15rem' }) => {
  const isApple = store.toLowerCase() === 'apple';
  const storeLink = isApple ? settings.appleStore : settings.googleStore;
  const openStore = () => {
    if (storeLink) {
      window.open(storeLink, '_blank');
    } else {
      alert('Not currently available.');
    }
  };
  const imgSrc = `${settings.assetServer}/images/common/button/${
    isApple ? 'app' : 'play'
  }-store-button.png`;
  return (
    <div
      onClick={openStore}
      style={{
        opacity: storeLink ? 1.0 : 0.5,
        zIndex: 1,
        position: 'relative',
        cursor: 'pointer',
      }}
    >
      <img alt="App Store" style={{ width }} src={imgSrc} />
    </div>
  );
};

AppButton.propTypes = {
  children: PropTypes.any,
  color: PropTypes.any,
  store: PropTypes.string,
};

export default AppButton;
