import React from 'react';
import settings from 'settings.js';
import { Markdown, Profile, Story } from 'components';

import author from 'assets/copy/stories/the-inside-outside/author.md';
import summary from 'assets/copy/stories/the-inside-outside/summary.md';
import specialThanks from 'assets/copy/stories/the-inside-outside/special-thanks.md';

import brianWilson from 'assets/copy/stories/the-inside-outside/actors/brian-anthony-wilson.md';
import damonCore from 'assets/copy/stories/the-inside-outside/actors/damon-core-jr.md';
import danielleLenee from 'assets/copy/stories/the-inside-outside/actors/danielle-lenee.md';
import melanyeFinister from 'assets/copy/stories/the-inside-outside/actors/melanye-finister.md';
import tyrahOdoms from 'assets/copy/stories/the-inside-outside/actors/tyrah-odoms.md';

const subnav = [
  { display: 'preview', anchor: 'preview' },
  { display: 'summary', anchor: 'summary' },
  { display: 'author', anchor: 'author' },
  { display: 'actors', anchor: 'actors' },
  { display: 'special thanks', anchor: 'thanks' },
];

const galleryUrl = `${settings.assetServer}/images/stories/the-inside-outside/gallery/gallery_`;
const gallery = [
  { src: `${galleryUrl}1.png` },
  { src: `${galleryUrl}2.png` },
  { src: `${galleryUrl}3.png` },
  { src: `${galleryUrl}4.png` },
  { src: `${galleryUrl}5.png` },
];

const TheInsideOutside = () => {
  return (
    <Story slug="the-inside-outside" subnav={subnav} gallery={gallery}>
      <Markdown anchor="summary" src={summary} />

      <br />
      <div id="author" />
      <Profile
        width={250}
        anchor="author"
        content={author}
        headshot="erin-mcmillon"
      />

      <div id="actors" />
      <h1>Actors</h1>
      <Profile content={brianWilson} headshot="brian-anthony-wilson" />
      <Profile content={damonCore} headshot="damon-core" />
      <Profile content={danielleLenee} headshot="danielle-lenée" />
      <Profile content={melanyeFinister} headshot="melanye-finister" />
      <Profile content={tyrahOdoms} headshot="tyrah-odoms" />

      <Markdown anchor="thanks" src={specialThanks} />
    </Story>
  );
};

export default TheInsideOutside;
