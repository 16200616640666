import React from 'react';
import { Button } from 'components';
import settings from 'settings.js';

const styles = {
  container: {
    backgroundColor: '#ffffff',
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '2rem',
    fontSize: '3rem',
    color: '#D03B01',
    maxWidth: '40rem',
    fontWeight: '900',
    margin: 'auto',
  },
};
const Survey = () => {
  return (
    <div style={styles.container}>
      <div>Let us get to know you as fellow explorers</div>
      <div style={{ marginTop: '2rem' }}>
        <Button url={settings.surveyLink} color="#D03B01">
          Take Our Survey
        </Button>
      </div>
    </div>
  );
};
export default Survey;
