import PropTypes from 'prop-types';
import React from 'react';
import Markdown from './Markdown';
import settings from 'settings.js';
import { layoutGenerator } from 'react-break';
const layout = layoutGenerator(settings.breakPoints);
const OnDesktop = layout.isAtLeast('tablet');
const OnMobile = layout.is('mobile');

const Profile = ({ width = 150, content, anchor, headshot }) => {
  return (
    <React.Fragment>
      <OnDesktop>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'top',
            marginTop: '1rem',
          }}
        >
          <div>
            <img
              alt={anchor}
              style={{ width, margin: '.3rem 0 0 0' }}
              src={`${settings.assetServer}/images/headshots/${headshot}.png`}
            />
          </div>
          <div style={{ marginLeft: '2rem' }}>
            <Markdown anchor={anchor} src={content} />
          </div>
        </div>
      </OnDesktop>
      <OnMobile>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'top',
          }}
        >
          <div style={{ marginTop: '2rem' }}>
            <img
              alt={anchor}
              style={{ width, margin: '.3rem 0 0 0' }}
              src={`${settings.assetServer}/images/headshots/${headshot}.png`}
            />
          </div>
          <div>
            <Markdown anchor={anchor} src={content} />
          </div>
        </div>
      </OnMobile>
    </React.Fragment>
  );
};

Profile.propTypes = {
  anchor: PropTypes.string,
  content: PropTypes.string,
  headshot: PropTypes.string,
  width: PropTypes.number,
};

export default Profile;
