import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import settings from 'settings.js';
import { layoutGenerator } from 'react-break';
const layout = layoutGenerator(settings.breakPoints);
const OnDesktop = layout.is('desktop');
const NotOnDesktop = layout.isAtMost('tablet');

const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div>
      <div style={styles.largeHrule} />
      <div style={styles.container}>
        <div style={{ margin: '0 0 0 2rem' }}>
          <OnDesktop>
            <Link to="/">
              <img
                alt="Trailoff Logo"
                height="70px"
                src={`${settings.assetServer}/images/common/logos/trailoff.png`}
              />
            </Link>
          </OnDesktop>
          <NotOnDesktop>
            <Link to="/">
              <img
                alt="Trailoff Logo"
                height="50px"
                src={`${settings.assetServer}/images/common/logos/trailoff.png`}
              />
            </Link>
          </NotOnDesktop>
        </div>
        <div style={{ margin: '0 0 0 1rem' }}>
          <OnDesktop>
            <Link to="/">
              <img
                alt="Trailoff"
                height="50px"
                src={`${settings.assetServer}/images/common/logos/trailoff-text.png`}
              />
            </Link>
          </OnDesktop>
          <NotOnDesktop>
            <Link to="/">
              <img
                alt="Trailoff "
                height="30px"
                src={`${settings.assetServer}/images/common/logos/trailoff-text.png`}
              />
            </Link>
          </NotOnDesktop>
        </div>
        <div
          style={{
            flexGrow: '1',
            textAlign: 'right',
            marginRight: '2rem',
            justifyContent: 'center',
          }}
        >
          <NotOnDesktop>
            <div onClick={() => setIsExpanded(!isExpanded)}>
              {(isExpanded && (
                <img
                  alt="close menu button"
                  src={`${settings.assetServer}/images/common/button/close.png`}
                />
              )) || (
                <img
                  alt="hamburger menu button"
                  src={`${settings.assetServer}/images/common/button/burger.png`}
                />
              )}
            </div>
            {isExpanded && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'fixed',
                  backgroundColor: 'black',
                  width: '100vw',
                  maxWidth: '378px',
                  top: '7.8rem',
                  right: 0,
                  height: 'calc(100vh - 7.8rem)',
                }}
              >
                <NavLinks
                  onClick={() => setIsExpanded(false)}
                  styles={{
                    ...styles,
                    link: {
                      ...styles.link,
                      color: 'white',
                    },
                    item: {
                      ...styles.item,
                      height: '4rem',
                      lineHeight: '4rem',
                      borderTop: '.25px solid #cccccc22',
                      '&:hover': {
                        backgroundColor: '#ff0000',
                      },
                    },
                  }}
                />
                <div
                  style={{
                    backgroundColor: 'white',
                    height: '5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      flexGrow: 1,
                      textAlign: 'left',
                      margin: '0 1rem 0 2rem',
                    }}
                  >
                    <div>
                      <Link
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                          fontSize: '1rem',
                        }}
                        to="/privacy-policy"
                      >
                        Privacy Policy
                      </Link>
                    </div>
                    <div>
                      <Link
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                          fontSize: '1rem',
                        }}
                        to="/terms-and-conditions"
                      >
                        Terms and Conditions
                      </Link>
                    </div>
                  </div>
                  <div style={{ width: '2rem', margin: '1rem' }}>
                    <a href={settings.facebookLink}>
                      <img
                        alt="facebook button"
                        src={`${settings.assetServer}/images/common/button/facebook.png`}
                      />
                    </a>
                  </div>
                  <div style={{ width: '2rem', margin: '0 2rem 0 0' }}>
                    <a href={settings.instagramLink}>
                      <img
                        alt="instagram button"
                        src={`${settings.assetServer}/images/common/button/instagram.png`}
                      />
                    </a>
                  </div>
                </div>
                <div style={{ backgroundColor: '#00AA9E', flexGrow: 1 }} />
              </div>
            )}
          </NotOnDesktop>
          <OnDesktop>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{ flexGrow: 1 }} />
              <NavLinks styles={styles} />
            </div>
          </OnDesktop>
        </div>
      </div>
    </div>
  );
};

const NavLinks = ({ styles, onClick }) => {
  return (
    <React.Fragment>
      <Link onClick={onClick} style={styles.link} to="/">
        <div style={styles.item}>Home</div>
      </Link>
      <Link onClick={onClick} style={styles.link} to="/about">
        <div style={styles.item}>About</div>
      </Link>
      <Link onClick={onClick} style={styles.link} to="/stories">
        <div style={styles.item}>Stories</div>
      </Link>
      <Link onClick={onClick} style={styles.link} to="/team">
        <div style={styles.item}>Team</div>
      </Link>
      <Link onClick={onClick} style={styles.link} to="/nature-allies">
        <div style={styles.item}>Nature Allies</div>
      </Link>
      <Link onClick={onClick} style={styles.link} to="/trails-and-watershed">
        <div style={styles.item}>Trails & Watershed</div>
      </Link>
      <Link onClick={onClick} style={styles.link} to="/connect">
        <div style={styles.item}>Connect</div>
      </Link>
    </React.Fragment>
  );
};

NavLinks.propTypes = {
  onClick: PropTypes.any,
  styles: PropTypes.object,
};

const styles = {
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  largeHrule: {
    backgroundColor: 'black',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    position: 'fixed',
    top: 0,
    width: '100vw',
    height: '.8rem',
    zIndex: 100,
  },
  container: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    position: 'fixed',
    top: '.8rem',
    height: '7rem',
    width: '100vw',
    zIndex: 200,
    textTransform: 'uppercase',
    margin: 'auto',
    alignItems: 'center',
  },
  item: {
    margin: '0 1rem 0 1rem',
    whitespace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
};
export default Navbar;
