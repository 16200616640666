import React from 'react';
import settings from 'settings.js';
import { Markdown, Profile, Story } from 'components';

import author from 'assets/copy/stories/appear-to-me/author.md';
import summary from 'assets/copy/stories/appear-to-me/summary.md';
import specialThanks from 'assets/copy/stories/appear-to-me/special-thanks.md';

import kiraRodriguez from 'assets/copy/stories/appear-to-me/musicians/kira-rodriguez.md';
import anthonyBriggs from 'assets/copy/stories/appear-to-me/actors/anthony-martinez-briggs.md';
import bradWrenn from 'assets/copy/stories/appear-to-me/actors/brad-wrenn.md';
import davidSweeny from 'assets/copy/stories/appear-to-me/actors/david-sweeny.md';
import freddyAmill from 'assets/copy/stories/appear-to-me/actors/freddy-amill.md';
import jaylaNicholas from 'assets/copy/stories/appear-to-me/actors/jayla-lavender-nicholas.md';
import kamiliFeelings from 'assets/copy/stories/appear-to-me/actors/kamili-feelings.md';
import malachiLily from 'assets/copy/stories/appear-to-me/actors/malachi-lily.md';
import michaelKiley from 'assets/copy/stories/appear-to-me/actors/michael-kiley.md';
import roberDaponte from 'assets/copy/stories/appear-to-me/actors/robert-daponte.md';
import rossBeschler from 'assets/copy/stories/appear-to-me/actors/ross-beschler.md';
import sabrinaPantal from 'assets/copy/stories/appear-to-me/actors/sabrina-pantal.md';
import stephenHailey from 'assets/copy/stories/appear-to-me/actors/stephen-hailey.md';
import tonyMoaton from 'assets/copy/stories/appear-to-me/actors/tony-moaton.md';

const subnav = [
  { display: 'preview', anchor: 'preview' },
  { display: 'summary', anchor: 'summary' },
  { display: 'author', anchor: 'author' },
  { display: 'actors', anchor: 'actors' },
  { display: 'musicians', anchor: 'musicians' },
  { display: 'special thanks', anchor: 'thanks' },
];

const galleryUrl = `${settings.assetServer}/images/stories/appear-to-me/gallery/gallery_`;
const gallery = [
  { src: `${galleryUrl}1.png` },
  { src: `${galleryUrl}2.png` },
  { src: `${galleryUrl}3.png` },
  { src: `${galleryUrl}4.png` },
  { src: `${galleryUrl}5.png` },
];

const AppearToMe = () => {
  return (
    <Story slug="appear-to-me" subnav={subnav} gallery={gallery}>
      <Markdown anchor="summary" src={summary} />

      <br />
      <div id="author" />
      <Profile
        width={250}
        anchor="author"
        content={author}
        headshot="eppchez"
      />

      <div id="actors" />
      <h1>Actors</h1>
      <Profile content={anthonyBriggs} headshot="anthony-martinez-briggs" />
      <Profile content={bradWrenn} headshot="brad-wrenn" />
      <Profile content={davidSweeny} headshot="dave-sweeny" />
      <Profile content={freddyAmill} headshot="freddy-amill" />
      <Profile content={jaylaNicholas} headshot="jayla-lavender" />
      <Profile content={kamiliFeelings} headshot="kamili-feelings" />
      <Profile content={malachiLily} headshot="malachi-lily" />
      <Profile content={michaelKiley} headshot="michael-kiley" />
      <Profile content={roberDaponte} headshot="robert-daponte" />
      <Profile content={rossBeschler} headshot="ross-beschler" />
      <Profile content={sabrinaPantal} headshot="sabrina-pantal" />
      <Profile content={stephenHailey} headshot="stephen-haily" />
      <Profile content={tonyMoaton} headshot="tony-moaton" />

      <div id="musicians" />
      <h1>Musicians</h1>
      <Profile content={kiraRodriguez} headshot="kira-rodriguez" />

      <Markdown src={specialThanks} />
    </Story>
  );
};

export default AppearToMe;
