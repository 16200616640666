import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import settings from 'settings.js';

const Input = ({ field, shape, isSelected, label, value, onSelect }) => {
  return (
    <div
      style={styles.flexRow}
      onClick={() => {
        if (typeof onSelect === 'function') onSelect(field, value);
      }}
    >
      <div style={styles.input}>
        <img
          alt={isSelected ? 'active' : 'inactive'}
          src={`${settings.assetServer}/images/common/button/${shape}-${
            isSelected ? 'active' : 'inactive'
          }.png`}
        />
      </div>
      <div style={styles.label}>{label}</div>
    </div>
  );
};

Input.propTypes = {
  field: PropTypes.any,
  isSelected: PropTypes.any,
  label: PropTypes.any,
  shape: PropTypes.any,
  value: PropTypes.any,
};

const Survey = () => {
  const refInnerFrame = useRef(null);
  const refOuterFrame = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const innerFrame = refInnerFrame.current;
      const outerFrame = refOuterFrame.current;
      if (window.innerWidth <= settings.breakPoints.desktop) {
        innerFrame.style.border = 0;
        outerFrame.style.border = 0;
      } else {
        innerFrame.style.border = '2px solid #F9A98E';
        outerFrame.style.border = '2px solid #F9A98E';
        outerFrame.style.width = `${innerFrame.clientWidth - 1005}px`;
        outerFrame.style.height = `75vh`;
        outerFrame.style.position = 'relative';
        outerFrame.style.top = '-35px';
        outerFrame.style.left = '-35px';
        outerFrame.style.padding = '2rem';
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <React.Fragment>
      <div>
        <div>
          <div
            ref={refInnerFrame}
            style={{
              position: 'relative',
              border: '2px solid #F9A98E',
              width: '90%',
              maxWidth: '60rem',
              margin: '0 auto 10rem auto',
            }}
          >
            <div ref={refOuterFrame}>
              <iframe
                title="Google Form"
                style={{ height: '100%', width: '100%' }}
                src="https://docs.google.com/forms/d/1UGaeOCHctDSdd8-PUnoGJU8xttiwIotZrSve8F7y3Ig/viewform?ts=5f8e2e1b&edit_requested=true"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const styles = {
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: '2rem',
    alignItems: 'center',
  },
  label: { fontSize: '1rem' },
  survey: {
    maxWidth: '100rem',
    color: 'white',
    backgroundImage: `url(${settings.assetServer}/images/common/torn-paper-large.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    margin: '4rem auto 0 auto',
    height: '20rem',
    textAlign: 'center',
  },
  surveyText: {
    fontFamily: 'ostrich_sans_inlineregular',
    color: 'white',
    fontSize: '6rem',
    position: 'absolute',
    width: '100%',
    height: '20rem',
    maxWidth: '100rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 900,
    fontSize: '1rem',
    textTransform: 'uppercase',
    margin: '2rem 0 2rem 2.5rem',
  },
  input: {
    minWidth: '3rem',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '0 1rem 0 0',
  },
  submitButton: {
    color: 'white',
    fontSize: '0.9rem',
    background: '#000000',
    width: '8rem',
    padding: '.8rem',
    textAlign: 'center',
    margin: '2rem',
  },
  htmlInput: {
    border: '1px solid black',
    borderRadius: '0.3rem',
    width: '15rem',
    height: '2.5rem',
    padding: '0 0 0 1rem',
    margin: '0 0 0 2.5rem',
  },
};

export default Survey;
