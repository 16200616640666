import React from 'react';
import { Button, AppButton } from 'components';
import { layoutGenerator } from 'react-break';
import settings from 'settings.js';
const layout = layoutGenerator(settings.breakPoints);
const OnDesktop = layout.is('desktop');
const Tablet = layout.is('tablet');
const Mobile = layout.is('mobile');

const styles = {
  desktop: {
    container: {
      backgroundColor: 'white',
      color: 'white',
      backgroundImage: `url(${settings.assetServer}/images/common/teaser-bg.png)`,
      backgroundPositionY: '10rem',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      maxWidth: '100rem',
      overflow: 'hidden',
      margin: '0 auto -36rem auto',
    },
    mockup: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '80rem',
      margin: '0 auto',
      zIndex: 5,
    },
    desc: {
      color: 'white',
      fontSize: '1rem',
      justifyContent: 'right',
      maxWidth: '35rem',
      marginBottom: '1rem',
    },
    titleBlock: {
      position: 'relative',
      padding: '2rem',
      textAlign: 'right',

      color: 'white',
      top: '-15rem',
    },
  },
  tablet: {
    container: {
      color: 'black',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'row',
    },
    subtitle: {
      textAlign: 'right',
      margin: '3rem',
      fontSize: '1.8rem',
      lineHeight: '2rem',
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'right',
    },
    desc: {
      color: 'white',
      margin: '2rem auto',
      maxWidth: '36rem',
      fontSize: '1rem',
    },
  },
  mobile: {
    container: {
      textAlign: 'center',
      backgroundColor: 'white',
      paddingTop: '1rem',
    },
    subtitle: {
      backgroundColor: 'white',
      color: 'black',
      textAlign: 'center',
      padding: '0 2rem 1rem 2rem',
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'white',
      textAlign: 'center',
      padding: '0 0 1rem 0',
    },
  },
};

const title = 'Experiences off the beaten path';
const desc =
  ' TrailOff features 10 original audio dramas, totally free to download through a cutting edge app, that unfold on trails across the Circuit Trails Network. Each story features a local author who brings a new and diverse perspective to getting out into nature.';
const subtitle =
  'Dive into captivating stories as you walk trails throughout the region, synced to you in real time.';
//backgroundPositionY: '5rem',
const AppTease = () => {
  return (
    <React.Fragment>
      <OnDesktop>
        <div style={styles.desktop.container}>
          <div style={styles.desktop.mockup}>
            <div>
              <img
                style={{ width: '30rem', margin: '0 2rem' }}
                alt="Mockup"
                src={`${settings.assetServer}/images/common/phone-mockup-scene.png`}
              />
            </div>
            <div style={{ color: 'black' }}>
              <div style={styles.tablet.subtitle}>{subtitle}</div>
              <div style={styles.tablet.buttons}>
                <div style={{ flexGrow: '1' }}></div>
                <div>
                  <AppButton width="12rem" store="apple" />
                </div>
                <div style={{ margin: '0 2.5rem 0 1.5rem' }}>
                  <AppButton width="12rem" store="google" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={styles.desktop.titleBlock}>
          <div
            style={{
              width: '75rem',
              margin: 'auto',
              top: '-20rem',
              textAlign: 'right',
              justifyContent: 'right',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ flexGrow: '1' }} />
            <div style={{ padding: '30rem 0 0 0' }}>
              <h1>{title}</h1>
              <div style={styles.desktop.desc}>{desc}</div>
              <Button url="about" color={'#ffffff'}>
                About Trailoff
              </Button>
            </div>
          </div>
        </div>
      </OnDesktop>
      <Tablet>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <img
              style={{ width: '30vw', margin: '0 2rem' }}
              alt="Mockup"
              src={`${settings.assetServer}/images/common/phone-mockup-scene.png`}
            />
          </div>
          <div>
            <div style={styles.tablet.subtitle}>{subtitle}</div>
            <div style={styles.tablet.buttons}>
              <div style={{ flexGrow: '1' }}></div>
              <div>
                <AppButton width="12rem" store="apple" />
              </div>
              <div style={{ margin: '0 2.5rem 0 1.5rem' }}>
                <AppButton width="12rem" store="google" />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            padding: '2rem',
            textAlign: 'center',
            backgroundColor: '#D03B01',
            color: 'white',
          }}
        >
          <h1>{title}</h1>
          <p style={styles.tablet.desc}>{desc}</p>
          <Button url="about" color={'#ffffff'}>
            About Trailoff
          </Button>
        </div>
      </Tablet>
      <Mobile>
        <div>
          <div style={styles.mobile.container}>
            <img
              alt="Mockup"
              style={{ width: '50vw' }}
              src={`${settings.assetServer}/images/common/phone-mockup-scene.png`}
            />
          </div>
          <div style={{ backgroundColor: '#D03B01', color: 'white' }}>
            <div style={styles.mobile.subtitle}>{subtitle}</div>
            <div>
              <div style={styles.mobile.buttons}>
                <div style={{ margin: '1rem 1rem 0 1rem' }}>
                  <AppButton width="10rem" store="apple" />
                </div>
                <div style={{ margin: '1rem 1rem 0 0' }}>
                  <AppButton width="10rem" store="google" />
                </div>
              </div>
            </div>
            <div>
              <div style={{ padding: '0 2rem 2rem 2rem', textAlign: 'center' }}>
                <h1>{title}</h1>
                <p style={{ color: 'white' }}>{desc}</p>
                <Button url="about" color={'#ffffff'}>
                  About Trailoff
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Mobile>
    </React.Fragment>
  );
};
export default AppTease;
