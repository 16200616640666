import React from 'react';
import settings from 'settings.js';
import { Markdown, Profile, Story } from 'components';

import author from 'assets/copy/stories/river-devil-ii/author.md';
import summary from 'assets/copy/stories/river-devil-ii/summary.md';
import specialThanks from 'assets/copy/stories/river-devil-ii/special-thanks.md';

import bradWrenn from 'assets/copy/stories/river-devil-ii/actors/brad-wrenn.md';
import brettRobinson from 'assets/copy/stories/river-devil-ii/actors/brett-ashley-robinson.md';
import davidSweeny from 'assets/copy/stories/river-devil-ii/actors/david-sweeny.md';
import jamesBaker from 'assets/copy/stories/river-devil-ii/actors/james-michael-baker.md';
import marthaStuckey from 'assets/copy/stories/river-devil-ii/actors/martha-stuckey.md';
import rachelRodriguez from 'assets/copy/stories/river-devil-ii/actors/rachel-ohanlon-rodriguez.md';
import robertDaponte from 'assets/copy/stories/river-devil-ii/actors/robert-daponte.md';
import susanneCollins from 'assets/copy/stories/river-devil-ii/actors/susanne-collins.md';
import tayshaCanales from 'assets/copy/stories/river-devil-ii/actors/taysha-marie-canales.md';

const subnav = [
  { display: 'preview', anchor: 'preview' },
  { display: 'summary', anchor: 'summary' },
  { display: 'author', anchor: 'author' },
  { display: 'actors', anchor: 'actors' },
  { display: 'special thanks', anchor: 'thanks' },
];

const galleryUrl = `${settings.assetServer}/images/stories/river-devil-ii/gallery/gallery_`;
const gallery = [
  {
    src: `${galleryUrl}1.png`,
    alt: 'Ducks',
  },
  {
    src: `${galleryUrl}2.png`,
    alt: 'Rocks',
  },
  {
    src: `${galleryUrl}3.png`,
    alt: 'Fish',
  },
  {
    src: `${galleryUrl}4.png`,
    alt: 'Mural',
  },
  {
    src: `${galleryUrl}5.png`,
    alt: 'Trail Marker',
  },
];

const RiverDevil = () => {
  return (
    <Story slug={'river-devil-ii'} subnav={subnav} gallery={gallery}>
      <Markdown anchor="summary" src={summary} />

      <div id="author" />
      <br />
      <Profile width={250} anchor="author" content={author} headshot="carmen" />

      <div id="actors" />
      <h1>Actors</h1>
      <Profile content={bradWrenn} headshot="brad-wrenn" />
      <Profile content={brettRobinson} headshot="brett-ashley-robinson" />
      <Profile content={davidSweeny} headshot="dave-sweeny" />
      <Profile content={jamesBaker} headshot="james-michael-baker" />
      <Profile content={marthaStuckey} headshot="martha-stuckey" />
      <Profile content={rachelRodriguez} headshot="rachel-o'hanlon-rodriguez" />
      <Profile content={robertDaponte} headshot="robert-daponte" />
      <Profile content={susanneCollins} headshot="susanne-collins" />
      <Profile content={tayshaCanales} headshot="taysha-canales" />

      <Markdown anchor="thanks" src={specialThanks} />
    </Story>
  );
};

export default RiverDevil;
