import React from 'react';
import settings from 'settings.js';
import {
  Navbar,
  Footer,
  Hero,
  AtTheCenter,
  Markdown,
  SubNavbar,
} from 'components';

import introduction from 'assets/copy/allies/introduction.md';
import bartrams from 'assets/copy/allies/bartrams-garden.md';
import schuylkill from 'assets/copy/allies/schuylkill-center-for-environmental-education.md';
import dl from 'assets/copy/allies/dl-national-heritage-corridor.md';
import seaport from 'assets/copy/allies/independence-seaport-museum.md';
import riverfront from 'assets/copy/allies/riverfront-north-partnership.md';
import perkiomen from 'assets/copy/allies/perkiomen-watershed-conservancy.md';
import chesterValley from 'assets/copy/allies/friends-of-the-chester-valley-trail.md';
import heinzNational from 'assets/copy/allies/heinz-national-wildlife-refuge.md';
import camdenCounty from 'assets/copy/allies/camden-county-environmental-center.md';
import ttfWatershed from 'assets/copy/allies/ttf-watershed-partnership.md';

const NatureAllies = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Hero
        title="Nature Allies"
        image={`${settings.assetServer}/images/hero/nature-allies.png`}
      />
      <SubNavbar
        links={[
          { display: 'introduction', anchor: 'introduction' },
          { display: 'allies', anchor: 'allies' },
        ]}
      />
      <div className={'inline'}>
        <Markdown anchor={'introduction'} src={introduction} />
        <h1>Allies</h1>
        <Markdown anchor={'bartrams'} src={bartrams} />
        <Markdown anchor={'allies'} src={schuylkill} />
        <Markdown anchor={'dl'} src={dl} />
        <Markdown anchor={'seaport'} src={seaport} />
        <Markdown anchor={'riverfront'} src={riverfront} />
        <Markdown anchor={'perkiomen'} src={perkiomen} />
        <Markdown anchor={'chesterValley'} src={chesterValley} />
        <Markdown anchor={'heinzNational'} src={heinzNational} />
        <Markdown anchor={'camdenCounty'} src={camdenCounty} />
        <Markdown anchor={'ttfWatershed'} src={ttfWatershed} />
      </div>
      <AtTheCenter />
      <Footer />
    </React.Fragment>
  );
};

export default NatureAllies;
