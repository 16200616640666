import PropTypes from 'prop-types';
import React, { useState } from 'react';

const Button = ({
  onClick,
  url,
  children,
  color,
  width,
  padding = '1.5rem',
}) => {
  const styles = {
    container: {
      fontFamily: 'Avenir Next W06 Regular',
      fontSize: '.8rem',
      display: 'inline-block',
      textAlign: 'center',
      textTransform: 'uppercase',
      whitespace: 'nowrap',
      minWidth: '15rem',
      cursor: 'pointer',
      width,
    },
    normal: {
      padding,
      border: `2px solid ${color}`,
      color: color,
    },
    hover: {
      color: color === 'black' || color === '#000000' ? 'white' : 'black',
      opacity: 0.5,
      backgroundColor: color,
      border: `2px solid ${color}`,
    },
  };
  const [hover, setHover] = useState(false);
  return (
    <div onClick={onClick} style={styles.container}>
      {(url && (
        <a href={url}>
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={
              hover ? { ...styles.normal, ...styles.hover } : styles.normal
            }
          >
            {children}
          </div>
        </a>
      )) || (
        <div
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={hover ? { ...styles.normal, ...styles.hover } : styles.normal}
        >
          {children}
        </div>
      )}
    </div>
  );
};

Button.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.any,
  color: PropTypes.any,
  padding: PropTypes.string,
  url: PropTypes.any,
  width: PropTypes.any,
};

export default Button;
