import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import settings from 'settings.js';
import { Button } from 'components';
import { layoutGenerator } from 'react-break';

const layout = layoutGenerator(settings.breakPoints);
const OnDesktop = layout.is('desktop');
const OnTablet = layout.is('tablet');
const OnMobile = layout.is('mobile');

const StoryItem = (props) => {
  return (
    <React.Fragment>
      <OnDesktop>
        <div style={styles.container}>
          <div style={styles.icon}>
            <img
              alt={`${props.title}`}
              style={styles.icon_img}
              src={`${settings.assetServer}/images/stories/${props.slug}/icon.png`}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <div style={styles.author}>{props.author}</div>
            <div style={styles.title}>{props.title}</div>
            <div style={styles.trail}>{props.trail}</div>
          </div>
          <div style={{ textAlign: 'right' }}>
            <Link to={`/story/${props.slug}`}>
              <Button color="black">Story Info</Button>
            </Link>
          </div>
        </div>
      </OnDesktop>
      <OnTablet>
        <div style={stylesTablet.container}>
          <div style={stylesTablet.icon}>
            <img
              alt={`${props.title}`}
              style={stylesTablet.icon_img}
              src={`${settings.assetServer}/images/stories/${props.slug}/icon.png`}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <div style={stylesTablet.author}>{props.author}</div>
            <div style={stylesTablet.title}>{props.title}</div>
            <div style={stylesTablet.trail}>{props.trail}</div>
          </div>
          <div>
            <div style={{ textAlign: 'right' }}>
              <Link to={`/story/${props.slug}`}>
                <Button color="black">Story Info</Button>
              </Link>
            </div>
          </div>
        </div>
      </OnTablet>
      <OnMobile>
        <div style={stylesMobile.container}>
          <div style={stylesMobile.icon}>
            <img
              alt={`${props.title}`}
              style={stylesMobile.icon_img}
              src={`${settings.assetServer}/images/stories/${props.slug}/icon.png`}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <div style={stylesMobile.author}>{props.author}</div>
            <div style={stylesMobile.title}>{props.title}</div>
            <div style={stylesMobile.trail}>{props.trail}</div>
            <div style={{ textAlign: 'right' }}>
              <Link to={`/story/${props.slug}`}>
                <Button color="black">Story Info</Button>
              </Link>
            </div>
          </div>
        </div>
      </OnMobile>
    </React.Fragment>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '1rem',
    width: '100%',
    padding: '2rem',
    borderBottom: '.1px solid grey',
  },
  icon: {
    borderRadius: '7rem',
    overflow: 'hidden',
    height: '7rem',
    width: '7rem',
    textAlign: 'center',
    paddingRight: '2rem',
  },
  icon_img: {
    width: '7rem',
  },
  author: {
    fontFamily: 'Avenir Next W06 Regular',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.5rem',
    marginBottom: '1rem',
  },
  title: {
    fontFamily: 'Avenir Next W06 Regular',
    fontStyle: 'oblique',
    fontWeight: 100,
    fontSize: '1rem',
    marginBottom: '1rem',
  },
  trail: {
    fontFamily: 'Avenir Next W06 Regular',
    fontWeight: 100,
    fontSize: '1rem',
    marginBottom: '1rem',
  },
};

const stylesMobile = {
  ...styles,
  container: {
    ...styles.container,
    alignItems: 'top',
    margin: 0,
    padding: '1rem 0 ',
  },
  icon: {
    borderRadius: '4rem',
    overflow: 'hidden',
    height: '4rem',
    width: '4rem',
    textAlign: 'center',
    padding: '.1rem 2rem 0 0',
  },
  icon_img: {
    width: '4rem',
  },
};

const stylesTablet = {
  ...stylesMobile,
  container: {
    ...styles.container,
    alignItems: 'center',
    margin: 0,
    padding: '2rem 0',
  },
};

export default StoryItem;

StoryItem.defaultProps = {};

StoryItem.propTypes = {
  slug: PropTypes.string,
  trail: PropTypes.string,
  title: PropTypes.string,
  author: PropTypes.string,
  icon: PropTypes.string,
};
