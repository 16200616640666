import React from 'react';
import settings from 'settings.js';
import { layoutGenerator } from 'react-break';
const layout = layoutGenerator(settings.breakPoints);
const OnMobile = layout.is('mobile');
const OnNotMobile = layout.isAtLeast('tablet');

const InlineVideo = () => {
  return (
    <React.Fragment>
      <OnMobile>
        <div
          style={{
            backgroundColor: 'black',
            height: '330px',
            overflow: 'hidden',
          }}
        >
          <div style={{ margin: '7.9rem auto', maxHeight: '3rem' }}>
            <video
              autoPlay
              controls
              style={{
                right: 0,
                bottom: 0,
                width: '100%',
                margin: '0 auto',
              }}
            >
              <source
                src={`${settings.assetServer}/video/trailoff_trailer.mp4`}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </OnMobile>

      <OnNotMobile>
        <div
          style={{
            backgroundColor: 'black',
            height: '864px',
            overflow: 'hidden',
          }}
        >
          <div style={{ margin: '7.9rem auto', maxHeight: '3rem' }}>
            <video
              autoPlay
              controls
              style={{
                right: 0,
                bottom: 0,
                height: '46rem',
                width: '100%',
                margin: '0 auto',
              }}
            >
              <source
                src={`${settings.assetServer}/video/trailoff_trailer.mp4`}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </OnNotMobile>
    </React.Fragment>
  );
};

export default InlineVideo;
