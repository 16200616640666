// https://assets.trailoff.org/www/images/stories/li-sumpter/lauren-mcbride@2x.png
export default {
  breakPoints: {
    mobile: 0,
    tablet: 415,
    desktop: 1200,
  },
  assetServer: 'https://assets.trailoff.org/www',
  facebookLink: 'https://www.facebook.com/SwimPony',
  instagramLink: 'https://www.instagram.com/swimponypa',
  swimponyLink: 'https://swimpony.org/',
  surveyLink: 'connect#survey',
  signupLink: '',
  pecLink: 'https://pecpa.org/',
  googleStore:
    'https://play.google.com/store/apps/details?id=com.theplacelab.trailoff',
  appleStore: 'https://apps.apple.com/us/app/trailoff/id1527184140',
};
