let styles = {
  padding: { flexGrow: 1 },
  container: {
    fontSize: '1rem',
    color: 'white',
    backgroundColor: 'black',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem 0 4rem 0',
    position: 'relative',
    bottom: 0,
    height: '100%',
  },
  navbarItem: { margin: '0 .75rem 1rem .75rem' },
  link: {
    textDecoration: 'none',
    color: 'white',
    textTransform: 'uppercase',
  },
  desktop: {
    copyright: { marginTop: '1rem' },
    signup: {
      textTransform: 'uppercase',
      padding: '2rem 0',
      fontWeight: 900,
    },
    email: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    emailInput: {
      height: '2rem',
      borderMargin: '1rem',
      width: '20rem',
    },
    hrule: {
      backgroundColor: 'rgb(114 114 114)',
      height: '.01rem',
      margin: '2rem 0 2rem 0',
    },
    leftCol: {
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'column',
    },
    rightCol: {
      display: 'flex',
      textAlign: 'right',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
    },
    fillerCol: { flexGrow: 1 },
    footerContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end',
    },
    pecLogo: {
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'column',
      margin: '0 2rem 0 2rem',
    },
    socialIcons: {
      display: 'flex',
      flexDirection: 'row',
      textAlign: 'center',
      alignItems: 'center',
    },
    footerLinks: {
      display: 'flex',
      flexDirection: 'row',
      padding: '5rem 0 4rem 0',
    },
  },
};

styles = {
  ...styles,
  tablet: {
    ...styles.desktop,
    signup: {
      ...styles.desktop.signup,
      textAlign: 'center',
      fontWeight: 100,
      margin: '-2rem 0 1rem 0',
    },
    mobileContainer: { display: 'flex', flexDirection: 'column' },
    email: { textAlign: 'center' },
    submitButton: { textAlign: 'center', marginTop: '1rem' },
    spLogo: {
      ...styles.desktop.spLogo,
      textAlign: 'center',
      marginTop: '2rem',
    },
    pecLogo: {
      ...styles.desktop.pecLogo,
      textAlign: 'center',
      marginTop: '2rem',
    },
    socialIcons: {
      textAlign: 'center',
      width: '10rem',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
    },
    photoCredit: {
      textAlign: 'center',
    },
    copyright: {
      textAlign: 'center',
      marginTop: '1rem',
    },
  },
};

styles = {
  ...styles,
  mobile: {
    ...styles.tablet,
    footerLinks: {
      ...styles.tablet.footerLinks,
      flexDirection: 'column',
      textAlign: 'center',
      margin: 0,
      padding: '2rem',
    },
  },
};

export default styles;
