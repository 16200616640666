import React from 'react';
import settings from 'settings.js';
import { Markdown, Profile, Story } from 'components';

import author from 'assets/copy/stories/where-the-light-wont-go/author.md';
import summary from 'assets/copy/stories/where-the-light-wont-go/summary.md';
import specialThanks from 'assets/copy/stories/where-the-light-wont-go/special-thanks.md';

import damonCore from 'assets/copy/stories/where-the-light-wont-go/actors/damon-core-jr.md';
import dianaRodriguez from 'assets/copy/stories/where-the-light-wont-go/actors/diana-rodriguez.md';
import ditoVanReigersberg from 'assets/copy/stories/where-the-light-wont-go/actors/dito-van-reigersberg.md';
import jHernandez from 'assets/copy/stories/where-the-light-wont-go/actors/j-hernandez.md';
import jaimeMaseda from 'assets/copy/stories/where-the-light-wont-go/actors/jaime-maseda.md';
import rachelOhanlonRodriguez from 'assets/copy/stories/where-the-light-wont-go/actors/rachel-ohanlon-rodriguez.md';
import tyrahOdoms from 'assets/copy/stories/where-the-light-wont-go/actors/tyrah-odoms.md';

const subnav = [
  { display: 'preview', anchor: 'preview' },
  { display: 'summary', anchor: 'summary' },
  { display: 'author', anchor: 'author' },
  { display: 'actors', anchor: 'actors' },
  { display: 'special thanks', anchor: 'thanks' },
];

const galleryUrl = `${settings.assetServer}/images/stories/where-the-light-wont-go/gallery/gallery_`;
const gallery = [
  { src: `${galleryUrl}1.png` },
  { src: `${galleryUrl}2.png` },
  { src: `${galleryUrl}3.png` },
  { src: `${galleryUrl}4.png` },
  { src: `${galleryUrl}5.png` },
];

const WhereTheLightWontGo = () => {
  return (
    <Story slug="where-the-light-wont-go" subnav={subnav} gallery={gallery}>
      <Markdown anchor="summary" src={summary} />

      <div id="author" />
      <br />
      <Profile
        width={250}
        anchor="author"
        content={author}
        headshot="ari"
      />

      <div id="actors" />
      <h1>Actors</h1>
      <Profile width={150} content={damonCore} headshot="damon-core" />
      <Profile
        width={150}
        content={dianaRodriguez}
        headshot="diana-rodriguez"
      />
      <Profile
        width={150}
        content={ditoVanReigersberg}
        headshot="dito-van-reigersberg"
      />
      <Profile width={150} content={jHernandez} headshot="j-hernandez" />
      <Profile width={150} content={jaimeMaseda} headshot="jaime-maseda" />
      <Profile
        width={150}
        content={rachelOhanlonRodriguez}
        headshot="rachel-o'hanlon-rodriguez"
      />
      <Profile width={150} content={tyrahOdoms} headshot="tyrah-odoms" />

      <Markdown anchor="thanks" src={specialThanks} />
    </Story>
  );
};

export default WhereTheLightWontGo;
