import React from 'react';
import settings from 'settings.js';
import { Markdown, Profile, Story } from 'components';

import author from 'assets/copy/stories/conversations/author.md';
import summary from 'assets/copy/stories/conversations/summary.md';
import specialThanks from 'assets/copy/stories/conversations/special-thanks.md';

import teriHislop from 'assets/copy/stories/conversations/musicians/teri-hislop.md';
import tyroneEllis from 'assets/copy/stories/conversations/musicians/tyrone-dancing-wolf-ellis-jr.md';

import cherlynnStevens from 'assets/copy/stories/conversations/actors/cherlynn-stevens.md';
import chevyMartinez from 'assets/copy/stories/conversations/actors/chevy-kaeo-martinez.md';
import jaylaNicholas from 'assets/copy/stories/conversations/actors/jayla-lavender-nicholas.md';
import ryanPierce from 'assets/copy/stories/conversations/actors/ryan-little-eagle-pierce.md';
import trinityNorwood from 'assets/copy/stories/conversations/actors/trinity-norwood.md';

const galleryUrl = `${settings.assetServer}/images/stories/conversations/gallery/gallery_`;
const gallery = [
  { src: `${galleryUrl}1.png` },
  { src: `${galleryUrl}2.png` },
  { src: `${galleryUrl}3.png` },
  { src: `${galleryUrl}4.png` },
  { src: `${galleryUrl}5.png` },
];

const Conversations = () => {
  return (
    <Story slug="conversations" gallery={gallery}>
      <Markdown anchor="summary" src={summary} />

      <div id="author" />
      <br />
      <Profile
        width={250}
        anchor="author"
        content={author}
        headshot="jacob-camacho"
      />

      <div id="actors" />
      <h1>Actors</h1>
      <Profile content={cherlynnStevens} headshot="cherlynn-stevens" />
      <Profile content={chevyMartinez} headshot="chevy-martinez" />
      <Profile content={jaylaNicholas} headshot="jayla-lavender" />
      <Profile content={ryanPierce} headshot="ryan-pierce" />
      <Profile content={trinityNorwood} headshot="trinity-norwood" />

      <div id="musicians" />
      <h1>Musicians</h1>
      <Profile content={teriHislop} headshot="teri-hislop" />
      <Profile content={tyroneEllis} headshot="tyrone-ellis-jr" />

      <Markdown anchor="thanks" src={specialThanks} />
    </Story>
  );
};

export default Conversations;
