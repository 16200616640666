import React from 'react';
import { Navbar, Markdown, Footer } from 'components';
import privacyPolicy from 'assets/copy/privacy-policy.md';

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <Navbar />
      <div className={'content'}>
        <h1>Privacy Policy</h1>
        <Markdown anchor={'PrivacyPolicy'} src={privacyPolicy} />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default PrivacyPolicy;
