import React from 'react';
import settings from 'settings.js';
import { Markdown, Profile, Story } from 'components';

import author from 'assets/copy/stories/a-sycamores-psalm/author.md';
import summary from 'assets/copy/stories/a-sycamores-psalm/summary.md';
import specialThanks from 'assets/copy/stories/a-sycamores-psalm/special-thanks.md';

import kinan from 'assets/copy/stories/a-sycamores-psalm/musicians/kinan-abou-afach.md';

const subnav = [
  { display: 'preview', anchor: 'preview' },
  { display: 'summary', anchor: 'summary' },
  { display: 'author', anchor: 'author' },
  { display: 'musicians', anchor: 'musicians' },
  { display: 'special thanks', anchor: 'thanks' },
];

const galleryUrl = `${settings.assetServer}/images/stories/a-sycamores-psalm/gallery/gallery_`;
const gallery = [
  { src: `${galleryUrl}1.png` },
  { src: `${galleryUrl}2.png` },
  { src: `${galleryUrl}3.png` },
  { src: `${galleryUrl}4.png` },
  { src: `${galleryUrl}5.png` },
];

const ASycamoresPsalm = () => {
  return (
    <Story slug="a-sycamores-psalm" subnav={subnav} gallery={gallery}>
      <Markdown anchor="summary" src={summary} />

      <div id="author" />
      <br />
      <Profile
        width={250}
        anchor="author"
        content={author}
        headshot="donia-salem-harhoor"
      />

      <div id="musicians" />
      <h1>Musicians</h1>
      <Profile
        width={150}
        anchor="kinan"
        content={kinan}
        headshot="kinan-abou-afach"
      />

      <Markdown anchor="thanks" src={specialThanks} />
    </Story>
  );
};

export default ASycamoresPsalm;
