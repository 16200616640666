import PropTypes from 'prop-types';
import React from 'react';
import settings from 'settings.js';

const StoryHeader = ({ title, author, trail, trailLink }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={styles.content}>
        <div
          style={{
            width: '90%',
            maxWidth: '50rem',
            color: 'white',
          }}
        >
          <div style={{ flexGrow: 1 }}></div>
          <div style={styles.title}>{title}</div>
          <div style={styles.author}>By {author}</div>
          <div style={styles.trail}>
            <a style={styles.trailLink} href={trailLink}>
              {trail}
            </a>
          </div>
        </div>
      </div>
      <div style={styles.container}>
        <img
          alt="torn paper"
          style={{ width: '100%', minHeight: '10rem' }}
          src={`${settings.assetServer}/images/common/torn-paper-large.png`}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    left: 0,
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
    maxWidth: '80rem',
    margin: '0 auto',
  },
  content: {
    position: 'absolute',
    margin: '-2rem auto 0 auto',
    zIndex: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
  },
  title: {
    fontSize: '2.5rem',
    fontFamily: 'ostrich_sans_inlineregular',
    color: 'white',
  },
  author: {
    fontSize: '1.2rem',
    margin: '.5rem 0',
    color: 'white',
  },
  trail: {
    fontSize: '1.2rem',
    color: 'white',
  },
  trailLink: {
    color: 'white',
    fontSize: '1.2rem',
    textDecoration: 'underline',
  },
};

StoryHeader.propTypes = {
  author: PropTypes.string,
  title: PropTypes.string,
  trail: PropTypes.string,
  trailLink: PropTypes.string,
};

export default StoryHeader;
