import PropTypes from 'prop-types';
import React from 'react';
import settings from 'settings.js';
import { layoutGenerator } from 'react-break';
const layout = layoutGenerator(settings.breakPoints);
const OnDesktop = layout.is('desktop');
const NotOnDesktop = layout.isAtMost('tablet');
const styles = {
  container: {
    height: '50rem',
    width: '100vw',
    backgroundColor: '#cccccc',
    display: 'flex',
    alignItems: 'center',
    marginTop: '8rem',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
  },
  containerSmall: {
    marginTop: '8rem',
    height: '30rem',
    width: '100vw',
    backgroundColor: '#cccccc',
    display: 'flex',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
  },
  title: {
    position: 'absolute',
    zIndex: 1,
    fontFamily: 'ostrich_sans_inlineregular',
    color: 'white',
    fontSize: '8rem',
    display: 'flex',
    alignItems: 'center',
    height: '50rem',
    marginLeft: '10rem',
  },
  titleCentered: {
    width: '100vw',
    position: 'absolute',
    zIndex: 1,
    fontFamily: 'ostrich_sans_inlineregular',
    color: 'white',
    fontSize: '8rem',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    height: '30rem',
    justifyContent: 'center',
  },
};

const Hero = ({ title, image, imageAlt }) => {
  return (
    <div>
      <OnDesktop>
        <div>
          <div style={styles.title}>{imageAlt ? '' : title}</div>
          <div style={styles.container}>
            <img
              alt={title}
              style={{ width: '100%', minHeight: '50rem', objectFit: 'cover' }}
              src={image}
            ></img>
          </div>
        </div>
      </OnDesktop>
      <NotOnDesktop>
        <div>
          <div style={styles.titleCentered}>{title}</div>
          <div style={styles.containerSmall}>
            <img
              alt={title}
              style={{ width: '100%', minHeight: '30rem', objectFit: 'cover' }}
              src={imageAlt ? imageAlt : image}
            ></img>
          </div>
        </div>
      </NotOnDesktop>
    </div>
  );
};

Hero.propTypes = {
  imageAlt: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
};

export default Hero;
