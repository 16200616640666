import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {
  About,
  Connect,
  Home,
  NatureAllies,
  StoryList,
  Team,
  TrailsAndWatershed,
  PrivacyPolicy,
  TermsAndConditions,
  WhereTheLightWontGo,
  ASycamoresPsalm,
  AppearToMe,
  ChroniclesOfAsylum,
  Conversations,
  DeeplyRouted,
  RiverDevil,
  TheInsideOutside,
  TheLandRemembers,
  TheWaySandWantsForWater,
} from 'pages';

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/connect">
            <Connect />
          </Route>
          <Route path="/nature-allies">
            <NatureAllies />
          </Route>
          <Route path="/stories">
            <StoryList />
          </Route>
          <Route path="/story/where-the-light-wont-go">
            <WhereTheLightWontGo />
          </Route>
          <Route path="/story/a-sycamores-psalm">
            <ASycamoresPsalm />
          </Route>
          <Route path="/story/appear-to-me">
            <AppearToMe />
          </Route>
          <Route path="/story/chronicles-of-asylum">
            <ChroniclesOfAsylum />
          </Route>
          <Route path="/story/conversations">
            <Conversations />
          </Route>
          <Route path="/story/deeply-routed">
            <DeeplyRouted />
          </Route>
          <Route path="/story/river-devil-ii">
            <RiverDevil />
          </Route>
          <Route path="/story/the-inside-outside">
            <TheInsideOutside />
          </Route>
          <Route path="/story/the-land-remembers">
            <TheLandRemembers />
          </Route>
          <Route path="/story/the-way-sand-wants-for-water">
            <TheWaySandWantsForWater />
          </Route>
          <Route path="/team">
            <Team />
          </Route>
          <Route path="/trails-and-watershed">
            <TrailsAndWatershed />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
