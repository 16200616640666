import React from 'react';
import settings from 'settings.js';
import { Markdown, Profile, Story } from 'components';

import author from 'assets/copy/stories/the-land-remembers/author.md';
import summary from 'assets/copy/stories/the-land-remembers/summary.md';
import specialThanks from 'assets/copy/stories/the-land-remembers/special-thanks.md';

import adrienneMackey from 'assets/copy/stories/the-land-remembers/actors/adrienne-mackey.md';
import simonCanusoKiley from 'assets/copy/stories/the-land-remembers/actors/simon-canuso-kiley.md';

const subnav = [
  { display: 'preview', anchor: 'preview' },
  { display: 'summary', anchor: 'summary' },
  { display: 'author', anchor: 'author' },
  { display: 'actors', anchor: 'actors' },
  { display: 'special thanks', anchor: 'thanks' },
];

const galleryUrl = `${settings.assetServer}/images/stories/the-land-remembers/gallery/gallery_`;
const gallery = [
  { src: `${galleryUrl}1.png` },
  { src: `${galleryUrl}2.png` },
  { src: `${galleryUrl}3.png` },
  { src: `${galleryUrl}4.png` },
  { src: `${galleryUrl}5.png` },
];

const TheLandRemembers = () => {
  return (
    <Story slug="the-land-remembers" subnav={subnav} gallery={gallery}>
      <Markdown anchor="summary" src={summary} />

      <br />
      <div id="author" />
      <Profile
        width={250}
        anchor="author"
        content={author}
        headshot="jacob-winterstein"
      />

      <div id="actors" />
      <h1>Actors</h1>
      <Profile content={adrienneMackey} headshot="adrienne-mackey" />
      <Profile content={simonCanusoKiley} headshot="simon-kiley" />
      <Markdown anchor="thanks" src={specialThanks} />
    </Story>
  );
};

export default TheLandRemembers;
