import React from 'react';
import settings from 'settings.js';
import {
  Navbar,
  Markdown,
  Footer,
  Hero,
  AtTheCenter,
  SubNavbar,
  Button,
} from 'components';
import howDoesItWork from 'assets/copy/about/how-does-it-work.md';
import whatIsIt from 'assets/copy/about/what-is-it.md';
import howWasItMade from 'assets/copy/about/how-was-it-made.md';
import specialThanks from 'assets/copy/about/special-thanks.md';
import ImageScroller from 'react-image-scroller';

const logoUrl = `${settings.assetServer}/images/common/logos`;
const galleryUrl = `${settings.assetServer}/images/gallery/about/gallery`;
const gallery = [
  {
    src: `${galleryUrl}1.png`,
    alt: 'Ducks',
  },
  {
    src: `${galleryUrl}2.png`,
    alt: 'Rocks',
  },
];
const About = () => {
  console.log(howDoesItWork);
  return (
    <React.Fragment>
      <Navbar />
      <Hero
        title="About"
        image={`${settings.assetServer}/images/hero/about.png`}
      />
      <SubNavbar
        links={[
          { display: 'What Is It?', anchor: 'what-is-it' },
          { display: 'How Does It Work?', anchor: 'how-does-it-work' },
          { display: 'Process', anchor: 'how-was-it-made' },
          { display: 'Credits', anchor: 'special-thanks' },
        ]}
      />
      <div className={'content'}>
        <Markdown anchor={'what-is-it'} src={whatIsIt} />
        <Markdown anchor={'how-does-it-work'} src={howDoesItWork} />
        <Markdown anchor={'how-was-it-made'} src={howWasItMade} />
        <Button color="black" url="/team">
          Team
        </Button>
        <Markdown anchor={'special-thanks'} src={specialThanks} />
      </div>

      <div className="content" style={styles.logoGroup}>
        <div style={styles.logoColumn}>
          <div style={styles.logo}>
            <img
              alt="Barra Foundation Logo"
              src={`${logoUrl}/barra_foundation_logo.png`}
            />
          </div>
          <div style={styles.logo}>
            <img
              alt="wyncote_foundation_logo"
              src={`${logoUrl}/wyncote_foundation_logo.png`}
            />
          </div>
          <div style={styles.logo}>
            <img
              alt="philadelphia_cultural_fund_logo"
              src={`${logoUrl}/philadelphia_cultural_fund_logo.png`}
            />
          </div>
        </div>
        <div style={styles.logoColumn}>
          <div style={styles.logo}>
            <img
              alt="penn council on the arts logo"
              src={`${logoUrl}/penn_council_on_the_arts_logo.png`}
            />
          </div>

          <div style={styles.logo}>
            <img
              alt="william_penn_logo"
              src={`${logoUrl}/william_penn_logo.png`}
            />
          </div>
          <div style={styles.logo}>
            <img
              alt="network_of_ensemble_theatres_logo"
              src={`${logoUrl}/network_of_ensemble_theatres_logo.png`}
            />
          </div>
        </div>
      </div>

      <ImageScroller>
        {gallery?.map((item, idx) => {
          return <img key={idx} src={item.src} alt={item.alt} />;
        })}
      </ImageScroller>

      <div style={{ height: '4rem' }} />

      <AtTheCenter />
      <Footer />
    </React.Fragment>
  );
};

const styles = {
  logoGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: '-3rem auto 0 auto',
  },
  logo: {
    display: 'flex',
    margin: '2rem',
    minWidth: '15rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default About;
