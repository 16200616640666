export default [
  {
    slug: 'the-way-sand-wants-for-water',
    title: 'The Way Sand Wants For Water',
    author: `Afaq`,
    trail: 'Cooper River Trail',
    color: '#7192B8',
    notes:
      'Low difficulty, out and back, pavement with optional off-trail dirt exploration, no inclines, light foot traffic',
    tags:
      'water, colonization, violence, healing, home, scars, Sudan, poetry, trees',
    length: '1.1 miles',
    trailLink: 'https://www.camdencounty.com/service/parks',
  },
  {
    slug: 'where-the-light-wont-go',
    title: "Where The Light Won't Go",
    author: 'Ari',
    trail: 'Chester Valley',
    color: '#D03B02',
    notes:
      'Medium to high difficulty, point to point (with short out & back section at the end), pavement and dirt, moderate inclines, heavy foot traffic, watch out for bikes',
    tags:
      'Latin, singing gods, trans, violence, afterworld, fairy tale, adventure, swearing',
    length: '2.6 miles',
    trailLink: 'www.chestervalleytrail.org',
  },
  {
    slug: 'river-devil-ii',
    title: 'River Devil II: The Return',
    author: 'Carmen Maria Machado',
    trail: 'Schuylkill River Trail: Manayunk to Shawmont',
    color: '#3097C3',
    notes:
      'Low to medium difficulty, point to point, pavement and gravel, no inclines, heavy foot traffic, watch out for bikes',
    tags:
      'monsters, murder, horror, The Jersey Devil, violence, Manayunk, humor, evil chanting, swearing',
    length: '1.9 miles',
    trailLink: 'http://www.schuylkillcenter.org/',
  },
  {
    slug: 'deeply-routed',
    title: 'Deeply Routed',
    author: 'Denise Valentine',
    trail: 'Tacony Creek Trail',
    color: '#F9A98E',
    notes:
      'Medium to high difficulty, point to point, pavement and gravel, moderate inclines, light foot traffic, watch for bikes',
    tags:
      'history, searching, slavery, unexpected connections, journey, intuition, unforgetting',
    length: '3.2 miles',
    trailLink: 'https://ttfwatershed.org/',
  },
  {
    slug: 'a-sycamores-psalm',
    title: "A Sycamore's Psalm",
    author: 'Donia Salem Harhoor',
    trail: 'Perkiomen Trail',
    color: '#8AC094',
    notes:
      'Medium to high difficulty, point to point, paved and gravel, moderate inclines, moderate foot traffic, watch for bikes',
    tags:
      'Egypt, care-giving, dementia, Montgomery County, family, natural history, diaspora, Arabic, trees, creek, poetry',
    length: '2.6 miles',
    trailLink: 'https://www.perkiomenwatershed.org/',
  },
  {
    slug: 'the-inside-outside',
    title: 'The Inside Outside',
    author: 'Erin McMillon',
    trail: 'D&L Trail',
    color: '#5FA211',
    notes:
      'Low difficulty, point to point, gravel, no inclines, moderate foot traffic, watch for bikes',
    tags:
      'epic battles, humor, monsters, anxiety dreams, violence, flashbacks, ancestors, swearing',
    length: '1.2 miles',
    trailLink: 'https://delawareandlehigh.org/',
  },
  {
    slug: 'appear-to-me',
    title: 'Appear To Me',
    author: 'Eppchez !',
    trail: 'Delaware River Trail South',
    color: '#7B5FB9',
    notes:
      'Medium difficulty, point to point, pavement with optional off-trail dirt exploration, no inclines, light foot traffic',
    tags:
      'queer history, feral cats, cruising, swearing, Wobblies, longshoreman, Yemayá, Lenni Lenape',
    length: '1.6 miles',
    trailLink: 'https://www.phillyseaport.org/',
  },
  {
    slug: 'conversations',
    title: 'Conversations',
    author: 'Jacob Camacho',
    trail: 'Kensington & Tacony Trail',
    color: '#FFE051',
    notes:
      'Low difficulty, point to point, pavement, no inclines, moderate foot traffic',
    tags:
      'Lenni Lenape, colonialism, Nihëlatàmweokàn, ancestral rage, time travel, water, We Are Still Here',
    length: '2.2 miles',
    trailLink: 'www.riverfrontnorth.org',
  },
  {
    slug: 'the-land-remembers',
    title: 'The Land Remembers',
    author: 'Jacob Winterstein',
    trail: 'John Heinz Refuge Trail',
    color: '#FBA41E',
    notes:
      'Medium difficulty, loop, gravel and dirt, small inclines, light foot traffic',
    tags:
      'race, childhood, history, friendship, nostalgia, pastrami sandwiches, Urban Renewal',
    length: '1.8 miles',
    trailLink: 'https://www.fws.gov/refuge/john_heinz',
  },
  {
    slug: 'chronicles-of-asylum',
    title: 'Chronicles of Asylum',
    author: 'Li Sumpter',
    trail: "Schuylkill River Trail: Bartram's Mile",
    color: '#BE4187',
    notes:
      'Medium difficulty, point to point, dirt and pavement, small inclines, light foot traffic',
    tags:
      'Afrofuturism, quest, survival, mystery, epic, modern myth, climate change, apocalypse',
    length: '1.6 miles',
    trailLink: 'https://bartramsgarden.org/',
  },
];
