import React from 'react';
import {
  Navbar,
  Footer,
  AtTheCenter,
  Hero,
  SubNavbar,
  Survey,
} from 'components';
import settings from 'settings.js';
import { layoutGenerator } from 'react-break';
const layout = layoutGenerator(settings.breakPoints);
const OnDesktop = layout.is('desktop');
const NotOnDesktop = layout.isAtMost('tablet');

const introText =
  'Who doesn’t love a newsletter? No, but seriously - most of the environmental organizations involved with TrailOff are looking forward to connecting with everyone in their community. The best way to find out about conversation work, volunteer opportunities, and community events is through their newsletters and email lists. Sign up on their websites to stay current (that’s a water pun).';

const Connect = () => {
  const spLogo = (
    <a href={settings.swimponyLink}>
      <img
        style={{ marginTop: '5rem' }}
        alt="SwimPony"
        src={`${settings.assetServer}/images/common/logos/sp-black.png`}
      />
    </a>
  );

  const instagramLogo = (
    <a href={settings.instagramLink}>
      <img
        style={{ marginRight: '2rem' }}
        alt="instagram button"
        src={`${settings.assetServer}/images/common/button/instagram.png`}
      />
    </a>
  );

  const facebookLogo = (
    <a href={settings.facebookLink}>
      <img
        alt="facebook button"
        src={`${settings.assetServer}/images/common/button/facebook.png`}
      />
    </a>
  );
  return (
    <React.Fragment>
      <Navbar />
      <Hero
        title="Connect"
        image={`${settings.assetServer}/images/hero/connect.png`}
      />
      <SubNavbar
        links={[
          { display: 'Connect', anchor: 'connect' },
          { display: 'Survey', anchor: 'survey' },
        ]}
      />
      <div>
        <div id="connect">
          <div style={styles.logos}>
            <div>{spLogo}</div>
            <div style={styles.socialIcons}>
              <div style={styles.socialIcon}>{instagramLogo}</div>
              <div style={styles.socialIcon}>{facebookLogo}</div>
            </div>
            <div style={{ margin: '3rem' }}>operations.manager@swimpony.org</div>
          </div>
        </div>
        <div id="survey" style={styles.survey}>
          <div style={styles.surveyText}>Take Our Survey!</div>
        </div>

        <OnDesktop>
          <div style={styles.introTextDesktop}>{introText}</div>
        </OnDesktop>
        <NotOnDesktop>
          <div style={{ margin: '3rem' }}>
            <div style={styles.introText}>{introText}</div>
          </div>
        </NotOnDesktop>

        <Survey />
      </div>
      <AtTheCenter />
      <Footer />
    </React.Fragment>
  );
};

const styles = {
  introTextDesktop: {
    textAlign: 'center',
    maxWidth: '50rem',
    margin: '3rem auto',
    fontSize: '1rem',
  },
  introText: {
    textAlign: 'left',
    maxWidth: '50rem',
    margin: 'auto',
    fontSize: '1rem',
  },
  logos: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',

    alignItems: 'center',
    justifyContent: 'center',
  },
  flexRow: { display: 'flex', flexDirection: 'row' },
  socialIcons: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'center',
  },
  socialIcon: { margin: '1rem' },
  imageGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '0 auto 0 auto',
    justifyContent: 'center',
  },
  imageBox: {
    width: '10rem',
    height: '10rem',
    border: '1px solid #979797',
    margin: '1rem',
    overflow: 'hidden',
  },
  survey: {
    maxWidth: '100rem',
    color: 'white',
    backgroundImage: `url(${settings.assetServer}/images/common/torn-paper-large.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    margin: '4rem auto 0 auto',
    height: '20rem',
    textAlign: 'center',
  },
  surveyText: {
    fontFamily: 'ostrich_sans_inlineregular',
    color: 'white',
    fontSize: '6rem',
    position: 'absolute',
    width: '100%',
    height: '20rem',
    maxWidth: '100rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  question: {
    fontWeight: 900,
    fontSize: '1.5rem',
    textTransform: 'uppercase',
    margin: '2rem 0 2rem 0',
  },
  input: {
    width: '10rem',
    textAlign: 'center',
    background: 'red',
  },
};

export default Connect;
