import React from 'react';
import settings from 'settings.js';
import {
  Navbar,
  StoryItem,
  Footer,
  AtTheCenter,
  Hero,
  SubNavbar,
} from 'components';
import stories from 'assets/copy/stories';

const StoryList = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Hero
        title="Stories"
        image={`${settings.assetServer}/images/hero/stories.png`}
        imageAlt={`${settings.assetServer}/images/hero/stories2.png`}
      />
      <SubNavbar
        links={[{ display: 'Introduction', anchor: 'introduction' }]}
      />
      <div className="content">
        <h1>Stories</h1>
        <p>
          Each TrailOff story is an original creation for its trail. Click on
          the map icons or the buttons below to learn more about that story, its
          trail, author and other info. Most trails close at sunset. Visit the
          trail partners' websites for details on hours of operation.
        </p>
        {stories.map((story, idx) => (
          <div key={idx}>
            <StoryItem {...story} />
          </div>
        ))}
      </div>
      <AtTheCenter />
      <Footer />
    </React.Fragment>
  );
};

export default StoryList;
