import React from 'react';
import { AppButton } from 'components';
import { layoutGenerator } from 'react-break';
import settings from 'settings.js';
const layout = layoutGenerator(settings.breakPoints);
const OnMobile = layout.is('mobile');
const NotOnMobile = layout.isAtLeast('tablet');

const styles = {
  container: {
    backgroundColor: '#00AB9E',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
  },
  headline: {
    fontWeight: '200',
    textTransform: 'uppercase',
    fontSize: '3rem',
    letterSpacing: '0.4rem',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '40rem',
    margin: '0 auto',
    padding: '2rem',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '5rem',
  },
};
const AtTheCenter = () => {
  return (
    <React.Fragment>
      <OnMobile>
        <div style={styles.container}>
          <div style={styles.innerContainer}>
            <div style={{ margin: '2rem 0' }}>
              Download for FREE and adventure into nature like you’ve never
              imagined.
            </div>
            <div style={{ marginRight: '2rem' }}>
              <AppButton store="google" width="10rem" />
            </div>
            <div>
              <AppButton store="apple" width="10rem" />
            </div>
          </div>
          <div style={{ alignSelf: 'bottom' }}>
            <img
              style={{ width: '5rem', margin: '0px 2rem -182px 0px' }}
              alt="Mockup"
              src={`${settings.assetServer}/images/common/phone-mockup-white.png`}
            />
          </div>
        </div>
      </OnMobile>
      <NotOnMobile>
        <div style={styles.container}>
          <div style={styles.innerContainer}>
            <div style={styles.headline}>
              STORIES THAT PUT YOU AT THE CENTER
            </div>
            <div style={{ margin: '2rem 0' }}>
              Download for FREE and adventure into nature like you’ve never
              imagined.
            </div>
            <div style={styles.buttons}>
              <div style={{ marginRight: '2rem' }}>
                <AppButton store="google" />
              </div>
              <div>
                <AppButton store="apple" />
              </div>
            </div>
          </div>
          <div>
            <img
              style={{ width: '20rem', margin: '2rem 15rem -1rem 0' }}
              alt="Mockup"
              src={`${settings.assetServer}/images/common/phone-mockup-white.png`}
            />
          </div>
        </div>
      </NotOnMobile>
    </React.Fragment>
  );
};
export default AtTheCenter;
