import React from 'react';
import { BlockImage } from 'components';
import settings from 'settings.js';

const styles = {
  container: { backgroundColor: 'white' },
};
const BehindTheScenes = () => {
  return (
    <div style={styles.container}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          margin: '3rem auto',
          justifyContent: 'center',
        }}
      >
        <BlockImage
          color="#8AC094"
          caption="stories that shift your perspective"
          src={`${settings.assetServer}/images/feature/feature1.png`}
          buttonText="Read About The Stories"
          buttonLink="stories"
          content="Jump into wild experiences created by 10 incredible authors"
        />
        <BlockImage
          color="#FBA41E"
          caption="SURROUNDED BY THE NATURAL WORLD"
          src={`${settings.assetServer}/images/feature/feature2.png`}
          buttonText="Read About The Project"
          buttonLink="about"
          content="Learn more about TrailOff’s trails, the larger Circuit Network, and the Delaware River Watershed"
        />
        <BlockImage
          color="#3097C3"
          caption="IN AN AUGMENTED REALITY JOURNEY"
          src={`${settings.assetServer}/images/feature/feature3.png`}
          buttonText="Read About The Watershed"
          buttonLink="trails-and-watershed"
          content="Using the magic of GPS triggering and binaural 360 audio, TrailOff syncs the experience to your movements and drops you at the center of the story"
        />
      </div>
    </div>
  );
};
export default BehindTheScenes;
