import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'components';
import { layoutGenerator } from 'react-break';
import settings from 'settings.js';
import styles from './styles.js';
const layout = layoutGenerator(settings.breakPoints);
const OnMobile = layout.is('mobile');
const OnTablet = layout.is('tablet');
const OnDesktop = layout.is('desktop');

const signupText = 'sign up for more trailoff updates';
const copyright = '© 2020 Swim Pony. All Rights Reserved.';
const photocredit =
  'All trail photos by John C Hawthorne (www.johnchawthorne.com).';

const spLogo = (
  <a href={settings.swimponyLink}>
    <img
      style={{ filter: 'invert(100%)' }}
      alt="SwimPony"
      src={`${settings.assetServer}/images/common/logos/sp-black.png`}
    />
  </a>
);

const instagramLogo = (
  <a href={settings.instagramLink}>
    <img
      style={{ filter: 'invert(100%)' }}
      alt="instagram button"
      src={`${settings.assetServer}/images/common/button/instagram.png`}
    />
  </a>
);

const facebookLogo = (
  <a href={settings.facebookLink}>
    <img
      style={{ filter: 'invert(100%)' }}
      alt="facebook button"
      src={`${settings.assetServer}/images/common/button/facebook.png`}
    />
  </a>
);

const pecLogo = (
  <a href={settings.pecLink}>
    <img
      style={{ filter: 'invert(100%)' }}
      alt="PEC"
      src={`${settings.assetServer}/images/common/logos/pec-black.png`}
    />
  </a>
);

const footerLinks = (
  <React.Fragment>
    <div style={{ flexGrow: '1' }} />
    <div style={styles.navbarItem}>
      <Link style={styles.link} to="/terms-and-conditions">
        Terms and Conditions
      </Link>
    </div>
    <div style={styles.navbarItem}>
      <Link style={styles.link} to="/privacy-policy">
        Privacy Policy
      </Link>
    </div>
  </React.Fragment>
);

const Footer = () => {
  return (
    <div style={styles.container}>
      <OnMobile>
        <div style={styles.mobile.mobileContainer}>
          <div style={styles.mobile.hrule} />
          <div style={styles.mobile.signup}>{signupText}</div>
          <div style={styles.mobile.email}></div>
          <div style={styles.mobile.submitButton}>
            <Button
              url="https://trailoff.us19.list-manage.com/subscribe/post?u=93d54d4cb380a2a7f62024183&id=5b20064094"
              width="20rem"
              padding="1rem"
              color="#cccccc"
            >
              Sign Up
            </Button>
          </div>
          <div style={styles.mobile.spLogo}>{spLogo}</div>
          <div style={styles.mobile.socialIcons}>
            <div style={styles.padding}>{instagramLogo}</div>
            <div style={styles.padding}>{facebookLogo}</div>
          </div>
          <div style={styles.mobile.pecLogo}>{pecLogo}</div>
          <div style={styles.mobile.footerLinks}>{footerLinks}</div>
          <div style={styles.mobile.photoCredit}>{photocredit}</div>
          <div style={styles.mobile.copyright}>{copyright}</div>
        </div>
      </OnMobile>
      <OnTablet>
        <div style={styles.tablet.mobileContainer}>
          <div style={styles.tablet.hrule} />
          <div style={styles.tablet.signup}>{signupText}</div>
          <div style={styles.tablet.email}></div>
          <div style={styles.tablet.submitButton}>
            <Button
              url="https://trailoff.us19.list-manage.com/subscribe/post?u=93d54d4cb380a2a7f62024183&id=5b20064094"
              width="20rem"
              padding="1rem"
              color="#cccccc"
            >
              Sign Up
            </Button>
          </div>
          <div style={styles.tablet.spLogo}>{spLogo}</div>
          <div style={styles.tablet.socialIcons}>
            <div style={styles.padding}>{instagramLogo}</div>
            <div style={styles.padding}>{facebookLogo}</div>
          </div>
          <div style={styles.tablet.pecLogo}>{pecLogo}</div>
          <div style={styles.tablet.footerLinks}>{footerLinks}</div>
          <div style={styles.tablet.photoCredit}>{photocredit}</div>
          <div style={styles.tablet.copyright}>{copyright}</div>
        </div>
      </OnTablet>
      <OnDesktop>
        <div style={styles.desktop.hrule} />
        <div style={styles.desktop.footerContent}>
          <div style={styles.desktop.leftCol}>
            <div>{spLogo}</div>
            <div style={styles.desktop.socialIcons}>
              <div style={styles.padding}>{instagramLogo}</div>
              <div style={styles.padding}>{facebookLogo}</div>
            </div>
          </div>
          <div style={styles.desktop.pecLogo}>{pecLogo}</div>
          <div style={styles.desktop.fillerCol} />
          <div style={styles.desktop.rightCol}>
            <div style={styles.desktop.signup}>{signupText}</div>
            <div>
              <div style={styles.desktop.email}>
                <div style={styles.padding} />
                <div></div>
                <div style={{ marginLeft: '2rem' }}>
                  <Button
                    url="https://trailoff.us19.list-manage.com/subscribe/post?u=93d54d4cb380a2a7f62024183&id=5b20064094"
                    color="#cccccc"
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            </div>
            <div style={styles.desktop.footerLinks}>{footerLinks}</div>
            <div>{photocredit}</div>
            <div style={styles.desktop.copyright}>{copyright}</div>
          </div>
        </div>
      </OnDesktop>
    </div>
  );
};

export default Footer;
