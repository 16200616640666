import React from 'react';
import settings from 'settings.js';
import { Markdown, Profile, Story } from 'components';

import author from 'assets/copy/stories/deeply-routed/author.md';
import summary from 'assets/copy/stories/deeply-routed/summary.md';
import specialThanks from 'assets/copy/stories/deeply-routed/special-thanks.md';

import cathySimpson from 'assets/copy/stories/deeply-routed/actors/cathy-simpson.md';
import owenValentine from 'assets/copy/stories/deeply-routed/musicians/owen-valentine.md';

const subnav = [
  { display: 'preview', anchor: 'preview' },
  { display: 'summary', anchor: 'summary' },
  { display: 'author', anchor: 'author' },
  { display: 'actors', anchor: 'actors' },
  { display: 'musicians', anchor: 'musicians' },
  { display: 'special thanks', anchor: 'thanks' },
];

const galleryUrl = `${settings.assetServer}/images/stories/deeply-routed/gallery/gallery_`;
const gallery = [
  { src: `${galleryUrl}1.png` },
  { src: `${galleryUrl}2.png` },
  { src: `${galleryUrl}3.png` },
  { src: `${galleryUrl}4.png` },
  { src: `${galleryUrl}5.png` },
];

const DeeplyRouted = () => {
  return (
    <Story slug="deeply-routed" subnav={subnav} gallery={gallery}>
      <Markdown anchor="summary" src={summary} />

      <div id="author" />
      <br />
      <Profile
        width={250}
        anchor="author"
        content={author}
        headshot="denise-valentine"
      />

      <div id="actors" />
      <h1>Actors</h1>
      <Profile content={cathySimpson} headshot="cathy-simpson" />

      <div id="musicians" />
      <h1>Musicians</h1>
      <Profile content={owenValentine} headshot="owen-valentine" />

      <Markdown anchor="thanks" src={specialThanks} />
    </Story>
  );
};

export default DeeplyRouted;
