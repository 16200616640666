import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

const Markdown = ({ src, anchor }) => {
  const [content, setContent] = useState('');
  useEffect(() => {
    fetch(src)
      .then((response) => response.text())
      .then((text) => {
        setContent(text);
      });
  }, [src]);

  return (
    <div className="markdown">
      <div
        style={{
          border: '1px solid red',
          display: 'block',
          position: 'relative',
          top: '-250px',
          visibility: 'hidden',
        }}
        id={anchor}
      />
      <ReactMarkdown source={content} />
    </div>
  );
};

Markdown.propTypes = {
  src: PropTypes.string,
};

export default Markdown;
