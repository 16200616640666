import React from 'react';
import { Navbar, Footer } from 'components';
import InlineVideo from './InlineVideo.js';
import AppTease from './AppTease.js';
import AtTheCenter from 'components/AtTheCenter.js';
import Survey from './Survey';
//import Signup from './Signup';
import Gallery from './Gallery';
import BehindTheScenes from './BehindTheScenes';

const Home = () => {
  return (
    <React.Fragment>
      <Navbar />
      <InlineVideo />
      <AppTease />
      <BehindTheScenes />
      <Gallery />
      <Survey />
      <AtTheCenter />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
