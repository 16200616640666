import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'components';
import settings from 'settings.js';
import { layoutGenerator } from 'react-break';
const layout = layoutGenerator(settings.breakPoints);
const OnDesktop = layout.is('desktop');
const OnTablet = layout.is('tablet');
const OnMobile = layout.is('mobile');

let Layout = ({
  styles,
  color,
  src,
  buttonText,
  buttonLink,
  caption,
  content,
}) => {
  styles = {
    ...styles,
    captionBlock: { ...styles.captionBlock, backgroundColor: color },
  };
  return (
    <div style={styles.container}>
      <div style={styles.frame}>
        <img style={styles.img} alt={caption} src={src} />
      </div>
      <div style={styles.captionBlock}>{caption}</div>
      <div style={styles.content}>{content}</div>
      <div style={{ textAlign: 'center' }}>
        <Button url={buttonLink} color="#000000">
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

Layout.propTypes = {
  buttonLink: PropTypes.any,
  buttonText: PropTypes.any,
  caption: PropTypes.string,
  color: PropTypes.string,
  content: PropTypes.string,
  src: PropTypes.string,
  styles: PropTypes.object,
};

const BlockImage = ({
  color,
  buttonText,
  buttonLink,
  src,
  caption,
  content,
}) => {
  let desktopStyle = {
    container: {
      textAlign: 'center',
      width: '20rem',
      margin: '-14rem 2rem 0 2rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '42rem',
    },
    frame: {
      display: 'inline-block',
      border: `1px solid ${color}`,
      padding: '1rem',
      textTransform: 'uppercase',
    },
    captionBlock: {
      fontFamily: 'ostrich_sans_inlineregular',
      fontSize: '2rem',
      position: 'relative',
      backgroundColor: color,
      width: '16rem',
      height: '10rem',
      display: 'flex',
      alignItems: 'center',
      margin: '-5rem 5rem 0 5rem',
      zIndex: 1,
      letterSpacing: '.4rem',
      padding: '1rem',
      textAlign: 'center',
    },
    content: {
      fontSize: '1.1rem',
      margin: '0 5rem 0 5rem',
      width: '20rem',
      flexGrow: '1',
      alignItems: 'center',
      display: 'flex',
      padding: '2rem',
    },
    img: {
      width: '20rem',
      height: '20rem',
      objectFit: 'cover',
    },
  };

  let tabletStyle = {
    ...desktopStyle,
    captionBlock: {
      ...desktopStyle.captionBlock,
      margin: '2rem 0 0 0',
      width: '28rem',
      justifyContent: 'center',
    },
    container: {
      ...desktopStyle.container,
      width: '40rem',
      border: '1px solid #aaaaaa',
      padding: '0 0 4rem 0',
      margin: '0 auto 0 auto',
    },
    frame: {
      ...desktopStyle.frame,
      width: '100%',
      padding: 0,
    },
    img: {
      width: '35rem',
      height: '20rem',
      padding: '1rem ',
      objectFit: 'cover',
    },
  };

  let mobileStyle = {
    ...tabletStyle,
    container: {
      width: '100vw',
      padding: '0 0 2rem 0',
      maxWidth: '100vw',
      overflow: 'hidden',
    },
    frame: {
      ...tabletStyle.frame,
      padding: 0,
      margin: 0,
      width: '100vw',
      border: '0',
    },
    img: { ...tabletStyle.img, padding: 0 },
    captionBlock: {
      ...tabletStyle.captionBlock,
      height: '8rem',
      justifyContent: 'center',
      border: 0,
      margin: '-.4rem 0 0 0',
      width: '100vw',
      maxWidth: '100vw',
    },
    content: {
      fontSize: '1.1rem',
      flexGrow: '1',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      width: '100vw',
      margin: '2rem 0 2rem 0',
      maxWidth: '100vw',
    },
  };
  return (
    <div>
      <OnDesktop>
        <Layout
          styles={desktopStyle}
          color={color}
          src={src}
          caption={caption}
          content={content}
          buttonText={buttonText}
          buttonLink={buttonLink}
        />
      </OnDesktop>
      <OnTablet>
        <Layout
          styles={tabletStyle}
          color={color}
          src={src}
          caption={caption}
          content={content}
          buttonText={buttonText}
          buttonLink={buttonLink}
        />
      </OnTablet>
      <OnMobile>
        <Layout
          styles={mobileStyle}
          color={color}
          src={src}
          caption={caption}
          content={content}
          buttonText={buttonText}
          buttonLink={buttonLink}
        />
      </OnMobile>
    </div>
  );
};

BlockImage.propTypes = {
  buttonLink: PropTypes.any,
  buttonText: PropTypes.any,
  caption: PropTypes.any,
  color: PropTypes.any,
  content: PropTypes.any,
  src: PropTypes.any,
};

export default BlockImage;
