import React from 'react';
import settings from 'settings.js';
import {
  Navbar,
  Footer,
  Hero,
  AtTheCenter,
  Markdown,
  SubNavbar,
  Button,
} from 'components';

import introduction from 'assets/copy/trails-and-watershed/introduction.md';
import pec from 'assets/copy/trails-and-watershed/pec.md';
import delaware from 'assets/copy/trails-and-watershed/delaware-river-watershed.md';
import circuit from 'assets/copy/trails-and-watershed/circuit-trails.md';
import alliance from 'assets/copy/trails-and-watershed/alliance-for-watershed-education.md';
import getInvolved1 from 'assets/copy/trails-and-watershed/get-involved-1.md';
import getInvolved2 from 'assets/copy/trails-and-watershed/get-involved-2.md';
import getInvolved3 from 'assets/copy/trails-and-watershed/get-involved-3.md';
import getInvolved4 from 'assets/copy/trails-and-watershed/get-involved-4.md';
import getInvolved5 from 'assets/copy/trails-and-watershed/get-involved-5.md';

const Watershed = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Hero
        title="Trails & Watershed"
        image={`${settings.assetServer}/images/hero/trails-and-watershed.png`}
      />
      <SubNavbar
        links={[
          { display: 'introduction', anchor: 'introduction' },
          { display: 'pec', anchor: 'pec' },
          { display: 'DE River Watershed', anchor: 'delaware' },
          { display: 'circuit trails', anchor: 'circuit' },
          { display: 'watershed education', anchor: 'alliance' },
          { display: 'get involved', anchor: 'getInvolved' },
        ]}
      />
      <div className={'inline'}>
        <Markdown anchor={'introduction'} src={introduction} />
        <Markdown anchor={'pec'} src={pec} />
        <Markdown anchor={'delaware'} src={delaware} />
        <Markdown anchor={'circuit'} src={circuit} />
        <Markdown anchor={'alliance'} src={alliance} />
        <Markdown anchor={'getInvolved'} src={getInvolved1} />
        <Markdown src={getInvolved2} />
        <Markdown src={getInvolved3} />
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '25rem' }}
        >
          <div>
            <Button url="https://www.usa.gov/elected-officials/" color="black">
              Contact Federal Elected Officials
            </Button>
          </div>
          <div style={{ marginTop: '1.5rem' }}>
            <Button
              url="https://www.legis.state.pa.us/cfdocs/legis/home/findyourlegislator/"
              color="black"
            >
              Contact PA State Elected Officials
            </Button>
          </div>
          <div style={{ marginTop: '1.5rem' }}>
            <Button url="https://www.njlcv.org/find/" color="black">
              Contact NJ State Elected Officials
            </Button>
          </div>
        </div>
        <Markdown src={getInvolved4} />
        <Button url={settings.surveyLink} color="black">
          Take Our Survey
        </Button>
        <Markdown src={getInvolved5} />
      </div>
      <AtTheCenter />
      <Footer />
    </React.Fragment>
  );
};

export default Watershed;
