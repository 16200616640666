import React from 'react';
import { Navbar, Markdown, Footer } from 'components';
import termsAndConditons from 'assets/copy/terms-and-conditions.md';

const TermsAndConditions = () => {
  return (
    <React.Fragment>
      <Navbar />
      <div className={'content'}>
        <h1>Terms And Conditions</h1>
        <Markdown anchor={'TermsAndCondtions'} src={termsAndConditons} />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default TermsAndConditions;
