import PropTypes from 'prop-types';
import React from 'react';

const StoryStats = ({ story }) => {
  const styles = {
    container: {
      maxWidth: '50rem',
      margin: 'auto',
      padding: '0 2rem',
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'column',
      color: '#6D7278',
      fontSize: '1rem',
      margin: '2rem 0 0 0',
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      margin: '1rem 0 0 0',
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.content}>
          <div>TRAIL NOTES: {story.notes}</div>
        </div>
        <div style={styles.content}>
          <div>TAGS: {story.tags}</div>
        </div>
        <div style={styles.content}>
          <div>TRAIL LENGTH: {story.length}</div>
        </div>
      </div>
    </div>
  );
};

StoryStats.propTypes = {
  story: PropTypes.shape({
    length: PropTypes.any,
    notes: PropTypes.any,
    tags: PropTypes.any,
  }),
};

export default StoryStats;
