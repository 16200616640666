import PropTypes from 'prop-types';
import React from 'react';
import {
  Navbar,
  Footer,
  AtTheCenter,
  Hero,
  SubNavbar,
  StoryHeader,
  StoryPreview,
  StoryStats,
} from 'components';
import settings from 'settings.js';
import stories from 'assets/copy/stories';
import ImageScroller from 'react-image-scroller';

const ASycamoresPsalm = ({ children, slug, subnav, gallery }) => {
  const story = stories.find((story) => story.slug === slug);
  return (
    <React.Fragment>
      <Navbar />
      <Hero
        title={story.title}
        image={`${settings.assetServer}/images/hero/${slug}.png`}
      />
      <SubNavbar links={subnav} />
      <StoryHeader
        title={story.title}
        author={story.author}
        trail={story.trail}
        trailLink={story.trailLink}
      />
      <StoryPreview color={story.color} title={story.title} slug={slug} />
      <StoryStats story={story} />
      <div className="content">{children}</div>
      {gallery && (
        <div>
          <ImageScroller>
            {gallery?.map((item, idx) => {
              return (
                <img key={idx} src={item.src} alt={item.alt ? item.alt : idx} />
              );
            })}
          </ImageScroller>
          <div className="content">
            <div
              style={{
                height: '8rem',
                display: 'flex',
                alignItems: 'center',
                fontSize: '1rem',
              }}
            >
              Photos by John C Hawthorne — www.johnchawthorne.com
            </div>
          </div>
        </div>
      )}
      <AtTheCenter />
      <Footer />
    </React.Fragment>
  );
};

ASycamoresPsalm.propTypes = {
  children: PropTypes.any,
  slug: PropTypes.string,
  subnav: PropTypes.array,
  gallery: PropTypes.array,
};

export default ASycamoresPsalm;
