import PropTypes from 'prop-types';
import React from 'react';
import {
  Navbar,
  Footer,
  Markdown,
  Hero,
  AtTheCenter,
  SubNavbar,
  Profile,
} from 'components';
import settings from 'settings.js';
import swimPony from 'assets/copy/team/producers/swim-pony.md';
import pec from 'assets/copy/team/producers/pec.md';
import specialThanks from 'assets/copy/team/special-thanks.md';
import coreCreators from 'assets/copy/team/core-creators.md';
import adrienneMackey from 'assets/copy/team/creators/adrienne-mackey.md';
import michaelKiley from 'assets/copy/team/creators/michael-kiley.md';
import mariaShaplin from 'assets/copy/team/creators/maria-shaplin.md';
import nickKrill from 'assets/copy/team/creators/nick-krill.md';
import echoes from 'assets/copy/team/creators/echoes.md';
import evaSteinmetz from 'assets/copy/team/creators/eva-steinmetz.md';
import jenniferSung from 'assets/copy/team/creators/jennifer-sung.md';
import saraWolff from 'assets/copy/team/creators/sara-wolff.md';
import lizzieHessek from 'assets/copy/team/creators/lizzie-hessek.md';
import tsHawkins from 'assets/copy/team/creators/ts-hawkins.md';
import christinaStarfire from 'assets/copy/team/creators/christina-starfire.md';
import vanessaOgbuehi from 'assets/copy/team/creators/vanessa-ogbuehi.md';
import johnHawthorne from 'assets/copy/team/creators/john-hawthorne.md';

const MarginImage = ({ link, imgUrl, content }) => {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          margin: '0 0 0 -12rem',
        }}
      >
        <a href={link}>
          <img alt="logo" style={{ width: '10rem' }} src={imgUrl} />
        </a>
      </div>
      <Markdown src={content} />
    </div>
  );
};

MarginImage.propTypes = {
  content: PropTypes.any,
  imgUrl: PropTypes.any,
  link: PropTypes.any,
};

const Team = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Hero
        title="Team"
        image={`${settings.assetServer}/images/hero/team.png`}
      />
      <SubNavbar
        links={[
          { display: 'Producers', anchor: 'producers' },
          { display: 'Core Creators', anchor: 'core' },
          { display: 'Special Thanks', anchor: 'thanks' },
        ]}
      />
      <div className={'content'}>
        <h1 id="producers">Producers</h1>
        <MarginImage
          content={swimPony}
          imgUrl={`${settings.assetServer}/images/common/logos/sp-black.png`}
          link={'https://www.swimpony.org'}
        />
        <MarginImage
          content={pec}
          imgUrl={`${settings.assetServer}/images/common/logos/pec-black.png`}
          link={'https://www.pecpa.org'}
        />
        <Markdown anchor="core" src={coreCreators} />
        <Profile
          width={150}
          content={adrienneMackey}
          headshot="adrienne-mackey"
        />
        <Profile width={150} content={michaelKiley} headshot="michael-kiley" />
        <Profile width={150} content={echoes} headshot="ECHOES" />
        <Profile width={150} content={mariaShaplin} headshot="maria-shaplin" />
        <Profile width={150} content={nickKrill} headshot="nick-krill" />
        <Profile width={150} content={evaSteinmetz} headshot="eva-steinmetz" />
        <Profile width={150} content={jenniferSung} headshot="jennifer-sung" />
        <Profile width={150} content={saraWolff} headshot="sara-wolff" />
        <Profile width={150} content={lizzieHessek} headshot="lizzie-hessek" />
        <Profile width={150} content={tsHawkins} headshot="ts-hawkins" />
        <Profile
          width={150}
          content={christinaStarfire}
          headshot="christina-starfire-may"
        />
        <Profile
          width={150}
          content={vanessaOgbuehi}
          headshot="vanessa-ogbuehi"
        />
        <Profile
          width={150}
          content={johnHawthorne}
          headshot="john-hawthorne"
        />
        <Markdown anchor="thanks" src={specialThanks} headshot="" />
      </div>
      <AtTheCenter />
      <Footer />
    </React.Fragment>
  );
};

export default Team;



