import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import settings from 'settings.js';
import { layoutGenerator } from 'react-break';
const layout = layoutGenerator(settings.breakPoints);
const OnDesktop = layout.is('desktop');

const styles = {
  hrule: { border: '1px solid #F9A98E', margin: '0 0 1rem 0' },
  gallery: {
    backgroundColor: '#F9A98E',
    height: '40rem',
    margin: '0 auto 1rem 0',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
};
const sliderImages = ['slider2.png', 'slider3.png'];
const sliderContents = sliderImages.map((image, idx) => (
  <div key={idx} data-src={`${settings.assetServer}/images/gallery/${image}`} />
));

const Gallery = () => {
  return (
    <OnDesktop>
      <div style={styles.hrule} />
      <div style={styles.hrule} />
      <div style={styles.gallery}>
        <div
          style={{
            width: '100%',
            maxWidth: '50rem',
            margin: '0',
          }}
        >
          <AwesomeSlider>{sliderContents}</AwesomeSlider>
        </div>
      </div>

      <div style={styles.hrule} />
      <div style={styles.hrule} />
    </OnDesktop>
  );
};
export default Gallery;
