import React from 'react';
import settings from 'settings.js';
import { Markdown, Profile, Story } from 'components';

import author from 'assets/copy/stories/chronicles-of-asylum/author.md';
import summary from 'assets/copy/stories/chronicles-of-asylum/summary.md';
import specialThanks from 'assets/copy/stories/chronicles-of-asylum/special-thanks.md';

import aaronShaw from 'assets/copy/stories/chronicles-of-asylum/actors/aaron-shaw.md';
import anitaHolland from 'assets/copy/stories/chronicles-of-asylum/actors/anita-holland.md';
import frankX from 'assets/copy/stories/chronicles-of-asylum/actors/frank-x.md';
import jahzeerTerrell from 'assets/copy/stories/chronicles-of-asylum/actors/jahzeer-terrell.md';
import jayleneOwens from 'assets/copy/stories/chronicles-of-asylum/actors/jaylene-clark-owens.md';
import laurenMcbride from 'assets/copy/stories/chronicles-of-asylum/actors/lauren-mcbride.md';
import linsaySmimling from 'assets/copy/stories/chronicles-of-asylum/actors/linsay-smiling.md';
import nazlahBlack from 'assets/copy/stories/chronicles-of-asylum/actors/nazlah-black.md';
import starfire from 'assets/copy/stories/chronicles-of-asylum/actors/starfire.md';
import steveWright from 'assets/copy/stories/chronicles-of-asylum/actors/steve-wright.md';
import ursulaRucker from 'assets/copy/stories/chronicles-of-asylum/actors/ursula-rucker.md';
import zuhairaMcgill from 'assets/copy/stories/chronicles-of-asylum/actors/zuhaira-mcgill.md';

const subnav = [
  { display: 'preview', anchor: 'preview' },
  { display: 'summary', anchor: 'summary' },
  { display: 'author', anchor: 'author' },
  { display: 'actors', anchor: 'actors' },
  { display: 'special thanks', anchor: 'thanks' },
];

const galleryUrl = `${settings.assetServer}/images/stories/chronicles-of-asylum/gallery/gallery_`;
const gallery = [
  { src: `${galleryUrl}1.png` },
  { src: `${galleryUrl}2.png` },
  { src: `${galleryUrl}3.png` },
  { src: `${galleryUrl}4.png` },
  { src: `${galleryUrl}5.png` },
];

const ChroniclesOfAsylum = () => {
  return (
    <Story slug="chronicles-of-asylum" subnav={subnav} gallery={gallery}>
      <Markdown anchor="summary" src={summary} />

      <div id="author" />
      <br />
      <Profile
        width={250}
        anchor="author"
        content={author}
        headshot="li-sumpter"
      />

      <div id="actors" />
      <h1>Actors</h1>
      <Profile anchor="author" content={aaronShaw} headshot="aaron-shaw" />
      <Profile content={anitaHolland} headshot="anita-holland" />
      <Profile content={frankX} headshot="frank-x" />
      <Profile content={jahzeerTerrell} headshot="jahzeer-terrell" />
      <Profile content={jayleneOwens} headshot="jaylene-clark-owens" />
      <Profile content={laurenMcbride} headshot="lauren-mcbride" />
      <Profile content={linsaySmimling} headshot="lindsay-smiling" />
      <Profile content={nazlahBlack} headshot="nazlah-black" />
      <Profile content={starfire} headshot="christina-starfire-may" />
      <Profile content={steveWright} headshot="steve-wright" />
      <Profile content={ursulaRucker} headshot="ursula-rucker" />
      <Profile content={zuhairaMcgill} headshot="zuhairah-mcgill" />

      <Markdown anchor={'thanks'} src={specialThanks} />
    </Story>
  );
};

export default ChroniclesOfAsylum;
