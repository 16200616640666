import PropTypes from 'prop-types';
import React, { useState } from 'react';
import settings from 'settings.js';
import { layoutGenerator } from 'react-break';

const layout = layoutGenerator(settings.breakPoints);
const OnDesktop = layout.is('desktop');
const NotOnDesktop = layout.isAtMost('tablet');

const SubNavbar = ({ links }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div style={styles.parentContainer}>
      <OnDesktop>
        <div style={styles.container}>
          <div
            style={{
              width: '50rem',
              margin: '0 auto',
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            <div
              style={{
                position: 'absolute',
                marginLeft: '-9rem',
              }}
            >
              <img
                alt="Trailoff"
                height="25rem"
                src={`${settings.assetServer}/images/common/logos/trailoff-text.png`}
              />
            </div>
            <div
              style={{
                margin: '0',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                textAlign: 'left',
              }}
            >
              {links?.map((link) => (
                <div key={link.anchor} style={styles.link}>
                  <a
                    style={{ textDecoration: 'none', color: 'black' }}
                    href={`#${link.anchor}`}
                  >
                    {link.display}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </OnDesktop>
      <NotOnDesktop>
        <div style={styles.containerCollapsed}>
          <div
            style={styles.containerCollapsedBar}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <div style={{ marginLeft: '2rem' }}>Jump To Section</div>
            <div style={styles.spacer} />
            <div style={{ marginRight: '2rem' }}>
              <img
                style={{ cursor: 'pointer' }}
                alt="chevron"
                src={`${settings.assetServer}/images/common/button/${
                  isExpanded ? 'chevron-down' : 'chevron-up'
                }.png`}
              />
            </div>
          </div>
          {isExpanded && (
            <div>
              {links?.map((link) => (
                <a
                  key={link.anchor}
                  onClick={() => setIsExpanded(false)}
                  style={{ textDecoration: 'none', color: 'black' }}
                  href={`#${link.anchor}`}
                >
                  <div style={styles.collapsedLink}>{link.display}</div>
                </a>
              ))}
            </div>
          )}
        </div>
      </NotOnDesktop>
    </div>
  );
};

SubNavbar.propTypes = {
  links: PropTypes.array,
};

const styles = {
  spacer: { flexGrow: 1 },
  link: {
    marginRight: '2rem',
    cursor: 'pointer',
  },

  parentContainer: {
    fontFamily: 'Avenir Next W06 Regular',
    fontSize: '1rem',
    textTransform: 'uppercase',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '3rem',
    borderBottom: '1px solid #DDDDDD',
  },
  containerCollapsed: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerCollapsedBar: {
    display: 'flex',
    flexDirection: 'row',
    width: '100vw',
    alignItems: 'center',
    height: '3rem',
    backgroundColor: '#F2F2F2',
  },
  collapsedLink: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1.5px solid #dddddd',
    height: '3rem',
    paddingLeft: '2rem',
  },
};
export default SubNavbar;
