import PropTypes from 'prop-types';
import React from 'react';
import settings from 'settings.js';
import { AudioPlayer } from 'components';

const StoryPreview = ({ slug, color }) => {
  return (
    <div style={{ textAlign: 'center', borderBottom: '2px solid #979797' }}>
      <div
        style={{
          maxWidth: '50rem',
          margin: '0 auto',
          padding: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div>
            <AudioPlayer
              title={'Preview'}
              color={color}
              playIcon={`${settings.assetServer}/images/stories/${slug}/play.png`}
              pauseIcon={`${settings.assetServer}/images/stories/${slug}/pause.png`}
              src={`${settings.assetServer}/audio/preview/${slug}.mp3`}
              storyIcon={`${settings.assetServer}/images/stories/${slug}/icon.png`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

StoryPreview.propTypes = {
  color: PropTypes.any,
  slug: PropTypes.any,
  title: PropTypes.string,
};

export default StoryPreview;
