import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { layoutGenerator } from 'react-break';
import settings from 'settings.js';
const layout = layoutGenerator(settings.breakPoints);
const OnMobile = layout.is('mobile');
const NotOnMobile = layout.isAtLeast('tablet');

const pad = (n, width, z = 0) => {
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

const counterFormat = (seconds) => {
  const minutes = Math.floor(seconds / 60) > 0 ? Math.floor(seconds / 60) : 0;
  const sec = Math.floor(seconds - minutes * 60);
  return `${pad(minutes, 2)}:${pad(sec, 2)}`;
};

const CustomAudioPlayer = ({
  title,
  src,
  playIcon,
  pauseIcon,
  color,
  storyIcon,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [percentComplete, setPercentComplete] = useState(0);
  const refTimeline = useRef(null);
  const refPlayer = useRef(null);
  const refCounter = useRef(null);
  const refPlayhead = useRef(null);

  const onSetPlayhead = (event) => {
    let newLeftMargin =
      event.clientX - refTimeline.current.getBoundingClientRect().left;
    newLeftMargin = newLeftMargin - refPlayhead.current.clientWidth / 2;
    newLeftMargin = newLeftMargin > 0 ? newLeftMargin : 0;
    newLeftMargin =
      newLeftMargin > refTimeline.current.clientWidth
        ? refTimeline.current.clientWidth
        : newLeftMargin;
    refPlayhead.current.style.marginLeft = `${newLeftMargin}px`;
    const percent = newLeftMargin / refTimeline.current.clientWidth;
    refPlayer.current.currentTime = refPlayer.current.duration * percent;
  };

  const onUpdateCounter = () => {
    refCounter.current.textContent = `${counterFormat(
      refPlayer.current?.currentTime
    )} / ${counterFormat(refPlayer.current?.duration)}`;
    setPercentComplete(
      Math.round(
        (refPlayer.current?.currentTime / refPlayer.current?.duration) * 100
      )
    );
  };

  const onTogglePlay = () => {
    if (isPlaying) {
      refPlayer.current.pause();
    } else {
      refPlayer.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const playHead = refPlayhead.current;
    const handler = () => {
      playHead.isDragging = true;
    };
    playHead.addEventListener('mousedown', handler);
    return () => playHead.removeEventListener('mousedown', handler);
  }, []);

  useEffect(() => {
    const playHead = refPlayhead.current;
    const handler = (event) => {
      if (playHead.isDragging) onSetPlayhead(event);
    };
    window.addEventListener('mousemove', handler);
    return () => window.removeEventListener('mousemove', handler);
  }, []);

  useEffect(() => {
    const handler = (event) => {
      if (refPlayhead.current.isDragging) {
        refPlayhead.current.isDragging = false;
        onSetPlayhead(event);
      }
    };
    window.addEventListener('mouseup', handler);
    return () => window.removeEventListener('mouseup', handler);
  }, []);

  useEffect(() => {
    const timeLine = refTimeline.current;
    timeLine.addEventListener('click', onSetPlayhead);
    return () => timeLine.removeEventListener('click', onSetPlayhead);
  }, []);

  useEffect(() => {
    const player = refPlayer.current;
    player.addEventListener('ended', () => {
      setIsPlaying(false);
    });
    return () =>
      player.removeEventListener('ended', () => {
        setIsPlaying(false);
      });
  }, []);

  useEffect(() => {
    const player = refPlayer.current;
    player.addEventListener('timeupdate', onUpdateCounter);
    return () => player.removeEventListener('timeupdate', onUpdateCounter);
  }, []);

  useEffect(() => {
    const player = refPlayer.current;
    player.addEventListener('canplaythrough', onUpdateCounter);
    return () => player.removeEventListener('canplaythrough', onUpdateCounter);
  }, []);

  return (
    <React.Fragment>
      <audio ref={refPlayer}>
        <source src={src} type="audio/mpeg" />
      </audio>
      <NotOnMobile>
        <div style={styles.container}>
          <div style={styles.storyIcon}>
            <img
              alt={`${title}`}
              style={styles.storyIcon_img}
              src={storyIcon}
            />
          </div>
          <div style={styles.playerContainer}>
            <div style={styles.title}>{title}</div>
            <div style={styles.player}>
              <div onClick={onTogglePlay}>
                <img
                  alt={isPlaying ? 'pause' : 'play'}
                  src={isPlaying ? pauseIcon : playIcon}
                />
              </div>
              <div style={styles.scrubber}>
                <div style={styles.progress}>
                  <div
                    ref={refTimeline}
                    style={{
                      ...styles.timeline,
                      background: color,
                    }}
                  >
                    <div
                      ref={refPlayhead}
                      style={{
                        ...styles.playhead,
                        background: color,
                        marginLeft: `${percentComplete}%`,
                      }}
                    ></div>
                  </div>
                </div>
                <div ref={refCounter} style={styles.counter} />
              </div>
            </div>
          </div>
        </div>
      </NotOnMobile>
      <OnMobile>
        <div>
          <div style={mobileStyles.container}>
            <div style={mobileStyles.storyIcon}>
              <img
                alt={`${title}`}
                style={mobileStyles.storyIcon_img}
                src={storyIcon}
              />
            </div>
            <div style={mobileStyles.playerContainer}>
              <div style={mobileStyles.title}>{title}</div>
              <div style={mobileStyles.player}>
                <div onClick={onTogglePlay}>
                  <img
                    alt={isPlaying ? 'pause' : 'play'}
                    src={isPlaying ? pauseIcon : playIcon}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={mobileStyles.scrubber}>
            <div style={mobileStyles.progress}>
              <div
                ref={refTimeline}
                style={{
                  ...mobileStyles.timeline,
                  background: color,
                }}
              >
                <div
                  ref={refPlayhead}
                  style={{
                    ...mobileStyles.playhead,
                    background: color,
                    marginLeft: `${percentComplete}%`,
                  }}
                ></div>
              </div>
            </div>
            <div ref={refCounter} style={mobileStyles.counter} />
          </div>
        </div>
      </OnMobile>
    </React.Fragment>
  );
};

CustomAudioPlayer.propTypes = {
  color: PropTypes.any,
  pauseIcon: PropTypes.any,
  playIcon: PropTypes.any,
  src: PropTypes.string,
  storyIcon: PropTypes.any,
  title: PropTypes.any,
};

export default CustomAudioPlayer;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  playerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  player: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    justifyContent: 'flex-start',
    textAlign: 'left',
    margin: '0 0 2rem 0',
  },
  scrubber: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 0 2rem',
  },
  progress: {
    textAlign: 'left',
  },
  counter: {
    textAlign: 'left',
    color: 'rgb(109, 114, 120)',
    fontSize: '.8rem',
    margin: '2.5rem 0 0 0 ',
  },
  timeline: {
    height: '3px',
    background: '#4200f7',
    margin: '1rem 1rem 0 0',
    float: 'left',
    borderRadius: '15px',
    width: '30rem',
    maxWidth: '28vw',
  },
  playhead: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '50%',
    marginTop: '-.65rem',
    background: 'rgba(0, 255, 196, 0.82)',
  },
  storyIcon: {
    height: '10rem',
    width: '10rem',
    textAlign: 'center',
    paddingRight: '2rem',
  },
  storyIcon_img: {
    width: '10rem',
  },
};
const mobileStyles = {
  ...styles,
  playerContainer: {
    ...styles.container,
    flexDirection: 'column',
  },
  scrubber: {
    ...styles.scrubber,
    width: '90vw',
    margin: 'auto',
  },
  timeline: {
    ...styles.timeline,
    width: '90vw',
    maxWidth: '90vw',
  },
};
